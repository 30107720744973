import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Step,
  // StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Formik } from "formik";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DefaultApiService from "../../../../../infrastructure/api/default";

const validationSchema = Yup.object().shape({
  adminComment: Yup.string().required("لطفا این فیلد را پر کنید."),
});

const InvoiceCheckModifyPage = () => {
  const data = useLoaderData("root");
  const { id: requestId } = useParams();
  let navigate = useNavigate();
  const [steps, setSteps] = useState(
    data?.stepList.map((step) => ({
      ...step,
      collapse:
        step.purchaseStepId && step.purchaseStepId <= data.requestInfo.purchaseStepId,
    }))
  );
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState({
    confirmLoading: false,
    cancelLoading: false,
    submitButtonType: null,
  });
  const [projectTitle, setProjectTitle] = useState(
    data?.factor?.title || ""
  );
  useEffect(() => {
    let index = data.stepList.findIndex(
      (x) => x.purchaseStepId === data.requestInfo.purchaseStepId
    );
    setActiveStep(index);
  }, [data]);

  const onNavigateToListPage = () => {
    navigate("/SHOP/Management/InvoiceCheck");
  };

  const handleSubmitButtonState = (type) => {
    setState((old) => ({ ...old, submitButtonType: type }));
  };

  const onClickCollapseButton = (collapse, index) => {
    if (index < activeStep) {
      let newSteps = steps;
      newSteps[index].collapse = !collapse;
      setSteps([...newSteps]);
    } else {
      toast.warning("فقط مراحل سپری شده، این امکان را دارند.");
    }
  };

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting, resetForm }) => {
    if (state.submitButtonType === "confirm") {
      onConfirmRequest(formValue, { setSubmitting, resetForm });
    } else {
      onCancelRequest(formValue, { setSubmitting });
    }
  };

  const onConfirmRequest = async (formValue, { setSubmitting, resetForm }) => {
    setState((old) => ({ ...old, confirmLoading: true }));
    try {
      const res = await DefaultApiService.postApi(
        "SHOP/Management/InvoiceCheck/ConfirmStep",
        { ...formValue, requestId }
      );

      if (res.isSuccess) {
        toast.success(res.message);
        setSubmitting(false);
        resetForm(true);
        if (activeStep === steps.length - 1) {
          onNavigateToListPage();
        } else {
          const newSteps = [...steps];
          newSteps[activeStep].adminComment = formValue.adminComment;
          setSteps(newSteps);
          setProjectTitle(steps[activeStep + 1]?.stepTitle || "");
          setActiveStep((step) => step + 1);
        }
      } else {
        toast.error(res.message);
      }
      setState((old) => ({ ...old, confirmLoading: false }));
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");
      setState((old) => ({ ...old, confirmLoading: false }));

      setSubmitting(false);
    }
  };

  const onCancelRequest = async (formValue, { setSubmitting }) => {
    onNavigateToListPage();
    setState((old) => ({ ...old, cancelLoading: true }));
    try {
      const res = await DefaultApiService.postApi(
        "SHOP/Management/InvoiceCheck/CancelRequest",
        { ...formValue, requestId }
      );

      if (res.isSuccess) {
        toast.success(res.message);
        setSubmitting(false);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }
      setState((old) => ({ ...old, cancelLoading: false }));
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");
      setState((old) => ({ ...old, cancelLoading: false }));

      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              requestId !== "create"
                ? `بررسی درخواست ${projectTitle}`
                : "ایجاد درخواست جدید"
            }
            subheader={"بررسی مراحل و تایید اسناد"}
          />

          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4} lg={3}>
                <Paper
                  elevation={3}
                  sx={{
                    p: 2,
                    height: "70vh",
                    overflow: "auto",
                    boxShadow: "0px 12px 21px 2px rgba(218, 230, 249, 0.4)",
                  }}
                >
                  <Box sx={{ maxWidth: 400 }}>
                    <Stepper activeStep={activeStep} orientation="vertical">
                      {steps.map((step, index) => (
                        <Step key={step.stepTitle}>
                          <StepLabel
                            optional={
                              index === steps.length - 1 ? (
                                <Typography variant="caption">
                                  آخرین مرحله
                                </Typography>
                              ) : null
                            }
                          >
                            {step.stepTitle}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </Paper>
              </Grid>

              <Grid container spacing={2} item xs={12} md={8} lg={9}>
                <Grid item xs={12} md={12}>
                  <Grid item xs={12} md={12} container className="custom-table">
                    <Grid item xs={12} md={12} className="row header">
                      <Typography
                        variant="h6"
                        fontWeight={"bold"}
                        color={"primary"}
                      >
                        {`اطلاعات درخواست(${data?.requestInfo?.stepTitle})`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={12} className="row" container>
                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          نام متقاضی
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.projectOwnerFirstName}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          نام خانوادگی متقاضی
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.projectOwnerLastName}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} className="row" container>
                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          عنوان پروژه
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.projectTitle}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          شماره پیگیری
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.referenceID}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} className="row" container>
                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          شهرستان
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.cityId}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          ایستگاه
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.substationId}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} className="row" container>
                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          فیدر
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.feederId}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} md={6} container>
                        <Grid item xs={6} md={6} className="cell title">
                          وضعیت
                        </Grid>
                        <Grid item xs={6} md={6} className="cell value">
                          {data?.requestInfo?.requestConfirm}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {steps.map((step, index) => (
                  <Grid item xs={12} md={12} key={index}>
                    <Paper
                      elevation={3}
                      sx={{
                        p: 2,
                        boxShadow: "0px 12px 21px 2px rgba(218, 230, 249, 0.4)",
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", mb: 1 }}
                      >
                        <IconButton
                          onClick={() =>
                            onClickCollapseButton(step.collapse, index)
                          }
                        >
                          {step.collapse ? (
                            <RemoveCircleOutlineIcon />
                          ) : (
                            <AddCircleOutlineIcon />
                          )}
                        </IconButton>
                        <Typography>{step.stepTitle}</Typography>
                        {index === activeStep && (
                          <Typography color={"primary"}>
                            (مرحله کنونی)
                          </Typography>
                        )}
                      </Box>

                      {index === activeStep ? (
                        // &&
                        // !(
                        //   index + 1 === steps.length &&
                        //   step.purchaseStepId === data.requestInfo.purchaseStepId
                        // )
                        <Formik
                          initialValues={{ adminComment: "" }}
                          validationSchema={validationSchema}
                          onSubmit={onSubmit}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            submitForm,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue,
                          }) => {
                            const onMakeFormikVariable = (name) => ({
                              name: name,
                              fullWidth: true,
                              value: values[name],
                              error: errors[name] && touched[name],
                              helperText:
                                errors[name] && touched[name] && errors[name],
                              variant: "outlined",
                            });

                            return (
                              <form onSubmit={handleSubmit}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} md={12} lg={12}>
                                    <TextField
                                      label="یادداشت مدیر"
                                      multiline
                                      minRows={4}
                                      onChange={handleChange}
                                      {...onMakeFormikVariable("adminComment")}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    container
                                    justifyContent={"flex-end"}
                                  >
                                    <Button
                                      disabled={isSubmitting}
                                      type="submit"
                                      onClick={() =>
                                        handleSubmitButtonState("cancel")
                                      }
                                      sx={{ mx: 1 }}
                                      color="secondary"
                                      variant="contained"
                                      size="large"
                                    >
                                      {state.cancelLoading && (
                                        <CircularProgress
                                          size={16}
                                          color="inherit"
                                        />
                                      )}
                                      لغو درخواست
                                    </Button>

                                    <Button
                                      disabled={isSubmitting}
                                      variant="contained"
                                      onClick={() =>
                                        handleSubmitButtonState("confirm")
                                      }
                                      size="large"
                                      type="submit"
                                    >
                                      {state.confirmLoading && (
                                        <CircularProgress
                                          size={16}
                                          color="inherit"
                                        />
                                      )}

                                      {index === steps.length - 1
                                        ? "پایان"
                                        : "تایید"}
                                    </Button>
                                  </Grid>
                                </Grid>
                              </form>
                            );
                          }}
                        </Formik>
                      ) : (
                        <Collapse
                          in={step.collapse}
                          timeout="auto"
                          unmountOnExit
                          sx={{ width: "100%" }}
                        >
                          {step.adminComment || "بدون نوشته"}
                        </Collapse>
                      )}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default InvoiceCheckModifyPage;
