import React from "react";

const SignupPage = () => {
  return (
    <>
      <h2>SignupPage</h2>
    </>
  );
};

export default SignupPage;
