import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik"; 
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs"; 
  
import DefaultApiService from "../../../../../infrastructure/api/default"; 
import JalaliDateTimePicker from "../../../../components/shared/jalali-datetimepicker";

const validationSchema = Yup.object().shape({
 
  title: Yup.string().required("لطفا این فیلد را پر کنید."),   
  isActive:  Yup.bool(), 
 
});

const AppVersionModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    navigate("/JP/Management/AppVersion");
  };
  
  
    

    


  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try {  
      
      
      
      
      const res = await DefaultApiService.modifyApi('/jp/Management/AppVersion',
        sendData
      );

      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create" ? `ویرایش ${data?.appVersion?.title}` : "ایجاد"
            }
          />

          <CardContent>
            <Formik
              initialValues={data.appVersion}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue                
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
 
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="عنوان"
                        variant="outlined"
                        fullWidth
                        name="title"
                        type="string"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title && touched.title}
                        helperText={
                          errors.title && touched.title && errors.title
                        }
                      />
                    </Grid> 

                    
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="نسخه"
                        variant="outlined"
                        fullWidth
                        name="version"
                        type="string"
                        value={values.version}
                        onChange={handleChange}
                        error={errors.version && touched.version}
                        helperText={
                          errors.version && touched.version && errors.version
                        }
                      />
                    </Grid> 


                    



                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="لینک مستقیم"
                        variant="outlined"
                        fullWidth
                        name="directUrl"
                        type="string"
                        value={values.directUrl}
                        onChange={handleChange}
                        error={errors.directUrl && touched.directUrl}
                        helperText={
                          errors.directUrl && touched.directUrl && errors.directUrl
                        }
                      />
                    </Grid> 








                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="لینک گوگل پلی"
                        variant="outlined"
                        fullWidth
                        name="playUrl"
                        type="string"
                        value={values.playUrl}
                        onChange={handleChange}
                        error={errors.playUrl && touched.playUrl}
                        helperText={
                          errors.playUrl && touched.playUrl && errors.playUrl
                        }
                      />
                    </Grid>




                    

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="لینک بازار"
                        variant="outlined"
                        fullWidth
                        name="bazarUrl"
                        type="string"
                        value={values.bazarUrl}
                        onChange={handleChange}
                        error={errors.bazarUrl && touched.bazarUrl}
                        helperText={
                          errors.bazarUrl && touched.bazarUrl && errors.bazarUrl
                        }
                      />
                    </Grid>




                    

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="لینک مایکت"
                        variant="outlined"
                        fullWidth
                        name="myketUrl"
                        type="string"
                        value={values.myketUrl}
                        onChange={handleChange}
                        error={errors.myketUrl && touched.myketUrl}
                        helperText={
                          errors.myketUrl && touched.myketUrl && errors.myketUrl
                        }
                      />
                    </Grid>


   
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isActive" 
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فعال"
                      />
                    </Grid>
  
  

   
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isForce" 
                        value={values.isForce}
                        checked={values.isForce}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فورس"
                      />
                    </Grid>
  

  
                    <Grid item xs={12} md={3} lg={2}>
                        <JalaliDateTimePicker
                          label="تاریخ انتشار"
                          onChange={(value) => {
                            setFieldValue("releaseDate", value);
                          }}
                          value={values.releaseDate}
                        />
                      </Grid>
 



                    <Grid item xs={12} md={12} lg={12}>
                        <div style={{}}>
                         
                            <CKEditor
                              editor={ClassicEditor}
                              data={values.changes || ""}
                              config={{
                                placeholder:
                                  "تغییرات مورد نظر را اینجا وارد کنید ...",
                                language: {
                                  ui: "fa", // Set the user interface language to Persian (Farsi)
                                  content: "fa", // Set the content language to Persian (Farsi)
                                  direction: "rtl", // Set the text direction to right-to-left (RTL)
                                },
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("changes", data);
                              }}
                            />
                    
                        </div>
                        {errors.changes && touched.changes && (
                          <div>{errors.changes}</div>
                        )}
                      </Grid>

  

                    <Grid item xs={12} md={12} lg={4}> 

  


</Grid>

 

                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      
                    </Grid>



                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={onNavigateToListPage}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default AppVersionModifyPage;
