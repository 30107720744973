import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tooltip,
  MenuItem as MenuItemField,
} from "@mui/material";
import WestIcon from "@mui/icons-material/West";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

import { Link } from "react-router-dom";
import MuiIcon from "../shared/icon/MuiIcon";

const MenuItem = ({ menu, isOpenedSideBar, setIsOpenedSideBar }) => {
  const location = useLocation();

  const [isTooltipOpened, setIsTooltipOpened] = useState(false);
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pathname, setPathname] = useState("");

  useEffect(() => {
    if (!isOpenedSideBar) {
      setIsShowSubMenu(false);
      setAnchorEl(null);
    }
  }, [isOpenedSideBar]);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const onClickMenuItem = async (event, menu) => {
    if (menu.child && Array.isArray(menu.child)) {
      event.preventDefault();
      setIsShowSubMenu(!isShowSubMenu);

      setAnchorEl(event.currentTarget);
      if (!isOpenedSideBar) {
      }
    } else {
      setIsOpenedSideBar(false);
      //dispatch(setSelectedMenuId(menu.id));
    }
  };

  const onClickSubMenuItem = async (menu, submenu) => {
    // dispatch(setSelectedMenuId(menu.id));
    setIsOpenedSideBar(false);

    onCloseAnchorEl();
  };

  const onCloseAnchorEl = () => {
    setIsShowSubMenu(false);
    setAnchorEl(null);
  };

  return (
    <ListItem
      sx={{ display: "block", p: "0.5rem" }}
      className="menu-item-container"
    >
      <Tooltip
        placement="left"
        title={menu?.title}
        arrow
        open={isTooltipOpened}
        onOpen={() => {
          if (!isOpenedSideBar) {
            setIsTooltipOpened(true);
          }
        }}
        onClose={() => setIsTooltipOpened(false)}
      >
        <Link
          to={menu?.link}
          onClick={(event) => {
            onClickMenuItem(event, menu);
          }}
        >
          <ListItemButton
            className={clsx({
              "menu-item-button": true,
              active:
                menu?.link === "/"
                  ? menu?.link === pathname
                  : menu.level !== 2 && pathname.includes(menu?.link),
              open: isOpenedSideBar,
            })}
            sx={{
              justifyContent: isOpenedSideBar ? "initial" : "center",
              px: 2,
            }}
            onClick={(event) => {
              onClickMenuItem(event, menu);
            }}
          >
            <ListItemIcon className="menu-icon">
              <MuiIcon name={menu?.icon} />
            </ListItemIcon>

            <ListItemText
              primary={menu.title}
              className="text"
              sx={{ opacity: isOpenedSideBar ? 1 : 0 }}
            />

            {menu.child && Array.isArray(menu.child) ? (
              <ListItemIcon
                className={clsx({
                  "submenu-icon": true,
                  rotate: isShowSubMenu,
                })}
                sx={{
                  ml: isOpenedSideBar ? 3 : "auto",
                }}
              >
                <KeyboardArrowLeftIcon />
              </ListItemIcon>
            ) : null}
          </ListItemButton>
        </Link>
      </Tooltip>

      {menu.child && Array.isArray(menu.child) ? (
        isOpenedSideBar ? (
          <Collapse
            in={isShowSubMenu && isOpenedSideBar}
            timeout="auto"
            unmountOnExit
            sx={{ mt: 0.5 }}
          >
            <List disablePadding className="submenu-list-container">
              {menu.child.map((submenu, index) => (
                <Link
                  to={submenu.link}
                  key={`${menu.id}-submenu${index}`}
                  onClick={() => {
                    onClickSubMenuItem(menu, submenu);
                  }}
                >
                  <ListItem key={index} disablePadding>
                    <ListItemButton
                      className={clsx({
                        "submenu-item": true,
                        active:
                          submenu?.link === "/"
                            ? submenu?.link === pathname
                            : submenu.level !== 2 && pathname === submenu?.link,
                      })}
                    >
                      <ListItemIcon sx={{ mx: 0, minWidth: "24px" }}>
                        <WestIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={submenu.title}
                        className="text"
                        sx={{ whiteSpace: "nowrap", overflow: "hidden" }}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))}
            </List>
          </Collapse>
        ) : (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onCloseAnchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
          >
            {menu.child.map((submenu, index) => (
              <Link
                to={submenu.link}
                onClick={() => {
                  onClickSubMenuItem(menu, submenu);
                }}
                key={`${menu.id}-submenu-popup-${index}`}
              >
                <MenuItemField
                  style={{
                    color:
                      submenu.level !== 2 && pathname.includes(submenu?.link)
                        ? "#fff"
                        : "#555555",
                    background:
                      submenu.level !== 2 && pathname.includes(submenu?.link)
                        ? "#163ba2"
                        : "#fff",
                  }}
                >
                  {submenu.title}
                </MenuItemField>
              </Link>
            ))}
          </Menu>
        )
      ) : null}
    </ListItem>
  );
};

export default MenuItem;
