import { api, authHeader } from "../configs/axiosConfigs";

// Category
const getCategoryListApi = async () => {
  const response = await api.request({
    url: `SHOP/Management/Category/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getCategoryByIdApi = async (id) => {
  const response = await api.request({
    url: `SHOP/Management/Category/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const modifyCategoryApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/Category/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteCategoryApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/Category/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 





// Brand
const getBrandListApi = async () => {
  const response = await api.request({
    url: `SHOP/Management/Brand/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getBrandByIdApi = async (id) => {
  const response = await api.request({
    url: `SHOP/Management/Brand/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const modifyBrandApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/Brand/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteBrandApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/Brand/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 





// Discount
const getDiscountListApi = async () => {
  const response = await api.request({
    url: `SHOP/Management/DiscountDefinition/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getDiscountByIdApi = async (id) => {
  const response = await api.request({
    url: `SHOP/Management/DiscountDefinition/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyDiscountApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/DiscountDefinition/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });
console.log(response);
  return response.data;
};

const deleteDiscountApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/DiscountDefinition/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
  

// CompletedBuy
const getCompletedBuyListApi = async () => {
  const response = await api.request({
    url: `SHOP/Management/CompletedBuy/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getCompletedBuyByIdApi = async (id) => {
  const response = await api.request({
    url: `SHOP/Management/CompletedBuy/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyCompletedBuyApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/CompletedBuy/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });
console.log(response);
  return response.data;
};

const deleteCompletedBuyApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/CompletedBuy/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
  


// PurchaseStep
const getPurchaseStepListApi = async () => {
  const response = await api.request({
    url: `SHOP/Management/PurchaseStep/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getPurchaseStepByIdApi = async (id) => {
  const response = await api.request({
    url: `SHOP/Management/PurchaseStep/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyPurchaseStepApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/PurchaseStep/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });
console.log(response);
  return response.data;
};

const deletePurchaseStepApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/PurchaseStep/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
  





// Product
const getProductListApi = async () => {
  const response = await api.request({
    url: `SHOP/Management/Product/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getProductByIdApi = async (id) => {
  const response = await api.request({
    url: `SHOP/Management/Product/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyProductApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/Product/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });
console.log(response);
  return response.data;
};

const deleteProductApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/Product/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
  
const addProductPriceApi = async (data) => {
   
  const response = await api.request({
    url: `SHOP/Management/Product/AddProductPrice`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });
  return response.data;
};



const deleteProductPriceApi = async (data) => {
  const response = await api.request({
    url: `SHOP/Management/Product/DeleteProductPrice`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};



const SHOPService = {
  getCategoryListApi,
  getCategoryByIdApi,
  modifyCategoryApi,
  deleteCategoryApi,

  
  getBrandListApi,
  getBrandByIdApi,
  modifyBrandApi,
  deleteBrandApi,
  
  getPurchaseStepListApi,
  getPurchaseStepByIdApi,
  modifyPurchaseStepApi,
  deletePurchaseStepApi,

  
  getProductListApi,
  getProductByIdApi,
  modifyProductApi,
  deleteProductApi,
  
  getDiscountListApi,
  getDiscountByIdApi,
  modifyDiscountApi,
  deleteDiscountApi,

  
  getCompletedBuyListApi,
  getCompletedBuyByIdApi,
  modifyCompletedBuyApi,
  deleteCompletedBuyApi,



  
  addProductPriceApi,
  deleteProductPriceApi


 
};

export default SHOPService;
