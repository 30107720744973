import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../components/shared/breadcrumbs";
import UserManagementService from "../../../../infrastructure/api/userManagement";
import RoleInfoSelect from "../../../components/user-management/RoleInfoSelect";
import TabPanel from "../../../components/shared/tab-panel";

const validationSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  userName: Yup.string().required("لطفا این فیلد را پر کنید."),
  email: Yup.string()
    .email("فرمت ایمیل اشتباه است.")
    .required("لطفا این فیلد را پر کنید."),
  mobile: Yup.string(),
  isActive: Yup.bool(),
  password: Yup.string().required("لطفا این فیلد را پر کنید."),
  confirmPassword: Yup.string()
    .required("لطفا این فیلد را پر کنید.")
    .oneOf([Yup.ref("password"), null], "رمزعبور و تکرار آن همخوانی ندارد."),
});

function a11yProps(index) {
  return {
    id: `userinfo-tab-${index}`,
    "aria-controls": `userinfo-tab-panel-${index}`,
    iconPosition: "start",
  };
}

const UserInfoModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: userId } = useParams();
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setLoading] = useState(false);
  const [cityPermission, setCityPermission] = useState(data.cityPermission);

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    debugger;
    const sendData = {
      ...data,
      userInfo: formValue,
      cityPermission: cityPermission,
    };
    console.log("sendData", sendData);

    try {
      const res = await UserManagementService.modifyUserInfoApi(sendData);

      if (res.isSuccess) {
        toast.success(res.message);
        navigate("/UM/Yeksa/userinfo");
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error("خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

     
 

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              userId !== "create"
                ? `ویرایش ${data?.userInfo?.firstName} ${data?.userInfo?.lastName}`
                : "ایجاد"
            }
          />

          <CardContent>
            <Formik
              initialValues={data?.userInfo}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleTabChange}
                      aria-label="basic tabs example"
                    >
                      <Tab label="اطلاعات کاربری" {...a11yProps(0)} />
                       
                    </Tabs>
                  </Box>
                  {/* User Info */}
                  <TabPanel value={tabValue} index={0}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="نام"
                          variant="outlined"
                          fullWidth
                          name="firstName"
                          type="string"
                          value={values.firstName}
                          onChange={handleChange}
                          error={errors.firstName && touched.firstName}
                          helperText={
                            errors.firstName &&
                            touched.firstName &&
                            errors.firstName
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="نام خانوادگی"
                          variant="outlined"
                          fullWidth
                          name="lastName"
                          type="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          error={errors.lastName && touched.lastName}
                          helperText={
                            errors.lastName &&
                            touched.lastName &&
                            errors.lastName
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="نام کاربری"
                          variant="outlined"
                          fullWidth
                          name="userName"
                          type="userName"
                          value={values.userName}
                          onChange={handleChange}
                          error={errors.userName && touched.userName}
                          helperText={
                            errors.userName &&
                            touched.userName &&
                            errors.userName
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="ایمیل"
                          variant="outlined"
                          fullWidth
                          name="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          error={errors.email && touched.email}
                          helperText={
                            errors.email && touched.email && errors.email
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="موبایل"
                          variant="outlined"
                          fullWidth
                          name="mobile"
                          type="mobile"
                          value={values.mobile}
                          onChange={handleChange}
                          error={errors.mobile && touched.mobile}
                          helperText={
                            errors.mobile && touched.mobile && errors.mobile
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <RoleInfoSelect
                          name="roleId"
                          roleList={data.roleList}
                          value={values.roleId}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="رمز عبور"
                          variant="outlined"
                          fullWidth
                          name="password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          error={errors.password && touched.password}
                          helperText={
                            errors.password &&
                            touched.password &&
                            errors.password
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="تکرار رمز عبور"
                          variant="outlined"
                          fullWidth
                          name="confirmPassword"
                          type="password"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          error={
                            errors.confirmPassword && touched.confirmPassword
                          }
                          helperText={
                            errors.confirmPassword &&
                            touched.confirmPassword &&
                            errors.confirmPassword
                          }
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={4}>
                        <FormControlLabel
                          name="isActive"
                          value={values.isActive}
                          checked={values.isActive}
                          onChange={handleChange}
                          control={<Switch />}
                          label="فعال"
                        />
                      </Grid>
                    </Grid>
                  </TabPanel>
 

                  <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    justifyContent={"flex-end"}
                    spacing={3}
                  >
                    <Button
                      disabled={isSubmitting}
                      variant="outlined"
                      size="large"
                      color="gray"
                      sx={{ mx: 1 }}
                      onClick={() => {
                        navigate("/UM/Yeksa/userinfo");
                      }}
                    >
                      انصراف
                    </Button>

                    <Button
                      disabled={isSubmitting}
                      variant="contained"
                      type="submit"
                      size="large"
                    >
                      {loading && (
                        <CircularProgress size={16} color="inherit" />
                      )}
                      {userId !== "create" ? "ویرایش" : "ایجاد"}
                    </Button>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default UserInfoModifyPage;
