import React from "react";

const NoMatch = () => {
  return (
    <>
      <h2>چنین صفحه ای وجود ندارد یا در درست ساخت است</h2>
    </>
  );
};

export default NoMatch;
