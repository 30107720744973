import React from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const RoleInfoSelect = ({ name, value, onChange, roleList }) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        id="role-info-select-label"
        sx={{
          background: "#fff",
          padding: "0 10px",
        }}
      >
        گروه کاربری
      </InputLabel>
      <Select
        labelId="role-info-select-label"
        id="role-info-select"
        name={name}
        value={value}
        onChange={onChange}
      >
        {roleList.map((x) => (
          <MenuItem key={x.roleId} value={x.roleId}>
            {x.roleName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default RoleInfoSelect;
