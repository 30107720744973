import { useEffect, useState } from "react";
import { Box, Button, Grid, Tab, Tabs } from "@mui/material";
import IconComponent from "../icon";
import PieChartIcon from "@mui/icons-material/PieChart";
import { ReactComponent as DocumentDownloadIcon } from "../../../assets/icons/document-download.svg";
import TabPanel from "../tab-panel";
import EnhancedTable from "../table";
import LineChartCustom from "../chart/LineChartCustom";
import BarChartCustom from "../chart/BarChartCustom";
import PieChartCustom from "../chart/PieChartCustom";

function a11yProps(index) {
  return {
    id: `report-tab-${index}`,
    "aria-controls": `report-tab-panel-${index}`,
    iconPosition: "start",
    index: index,
  };
}

const ReportDataShow = ({ tableData, chartData, loading = false }) => {
  const [tabItems, setTabItems] = useState([
    {
      type: "grid",
      label: "اطلاعات",
      icon: <IconComponent name="grid" width="22px" height="22px" />,
    },
  ]);
  const [tabValue, setTabValue] = useState(0);
  const [pieChart, setPieChart] = useState([]);

  useEffect(() => {
    if (chartData?.seriesTitle?.length > 0 && chartData.data?.length > 0) {
      const pie = chartData.seriesTitle
        .map((ser) => ({
          name: ser,
          value: parseFloat(
            chartData.data
              .reduce((total, obj) => (total += obj[ser]), 0)

              .toFixed(2)
          ),
        }))
        .filter((x) => x.value >= 0);

      setPieChart(pie);
    }

    const newTabs = [
      {
        type: "grid",
        label: "اطلاعات",
        icon: <IconComponent name="grid" width="22px" height="22px" />,
      },
    ];

    if (chartData.line) {
      newTabs.push({
        type: "line",
        label: "چارت خطی",
        icon: <IconComponent name="diagram" width="22px" height="22px" />,
      });
    }

    if (chartData.column) {
      newTabs.push({
        type: "column",
        label: "چارت ستونی",
        icon: <IconComponent name="chart" width="22px" height="22px" />,
      });
    }

    if (chartData.pie) {
      newTabs.push({
        type: "pie",
        label: "چارت دایره‌ای",
        icon: <PieChartIcon width="22px" height="22px" color="success" />,
      });
    }
    setTabItems(newTabs);
  }, [chartData]);

  const renderTabTemplate = (tab) => {
    switch (tab.type) {
      case "grid":
        return (
          <Grid container>
            <Grid item xs={12} md={12}>
              <EnhancedTable
                rowKey={"id"}
                rows={tableData.data}
                headCells={tableData.columns}
                pageSize={10}
                showFilter={false}
                showIndex={false}
                loading={loading}
              />
            </Grid>
          </Grid>
        );
      case "line":
        return (
          <LineChartCustom
            title={chartData.title}
            xAxisTitle={chartData.xAxisTitle}
            yAxisTitle={chartData.yAxisTitle}
            data={chartData.data}
            series={chartData.seriesTitle}
            loading={loading}
            showBrush={true}
            // xAxis="index"
            // xType="number"
          />
        );
      case "column":
        return (
          <BarChartCustom
            title={chartData.title}
            xAxisTitle={chartData.xAxisTitle}
            yAxisTitle={chartData.yAxisTitle}
            data={chartData.data}
            series={chartData.seriesTitle}
            loading={loading}
            showBrush={true}
          />
        );
      case "pie":
        return (
          <PieChartCustom
            title={chartData.title}
            data={pieChart}
            loading={loading}
            showBrush={true}
          />
        );
      default:
        break;
    }
  };
  return (
    <Grid container item xs={12} md={12}>
      <Grid item xs={12} md={12}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            position: "relative",
          }}
        >
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              // const target = event.target.getAttribute("index");
              setTabValue(newValue);
            }}
            scrollButtons="auto"
            allowScrollButtonsMobile
          >
            {tabItems.map((tab, index) => (
              <Tab
                label={tab.label}
                icon={tab.icon}
                {...a11yProps(index)}
                key={index}
              />
            ))}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="outlined"
                color="success"
                startIcon={<DocumentDownloadIcon width={16} height={16} />}
              >
                دریافت فایل اکسل
              </Button>
            </Box>
          </Tabs>
        </Box>
      </Grid>

      <Grid item xs={12} md={12}>
        {tabItems.map((tab, index) => (
          <TabPanel value={tabValue} index={index} key={index}>
            {renderTabTemplate(tab)}
          </TabPanel>
        ))}
      </Grid>
    </Grid>
  );
};

export default ReportDataShow;
