import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterJalali from "@date-io/date-fns-jalali";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toMiladyFullDate } from "../../../middleware/middleware";
import { MobileDateTimePicker } from "@mui/x-date-pickers";

const JalaliDateTimePicker = ({
  value,
  label,
  onChange,
  size = "medium",
  ...other
}) => {
  // const [value, setValue] = React.useState(new Date(2022, 3, 7));

  return (
    <LocalizationProvider dateAdapter={AdapterJalali}>
      <MobileDateTimePicker
        mask="____/__/__"
        value={toMiladyFullDate(value)}
        label={label}
        ampm={false}
        onChange={(newValue) => {
          const date = toMiladyFullDate(newValue);
          onChange(date);
        }}
        renderInput={(params) => (
          <TextField {...other} {...params} size={size} />
        )}
        DialogProps={{}}
      />
    </LocalizationProvider>
  );
};

export default JalaliDateTimePicker;
