import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import MenuItem from "./MenuItem";
import { getUserMenuList } from "../../../application/selectors/user";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  top: "4rem",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const SideBarContainer = ({ open, setOpen }) => {
  const menuList = useSelector(getUserMenuList);

  return (
    <>
      {/* Desktop Navbar component */}
      <ClickAwayListener
        mouseEvent="onMouseDown"
        touchEvent="onTouchStart"
        onClickAway={(event) => {
          if (!(event.target && event.target.id === "side-bar-icon")) {
            open && setOpen(false);
          }
        }}
      >
        <Drawer
          variant="permanent"
          open={open}
          className="side-bar-container"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <DrawerHeader />
          <Divider />
          <List>
            <MenuItem
              menu={{ title: "داشبورد", link: "/", icon: "Home" }}
              isOpenedSideBar={open}
              setIsOpenedSideBar={setOpen}
            />
            {React.Children.toArray(
              menuList &&
                menuList.map((menu) => (
                  <MenuItem
                    key={menu.id}
                    menu={menu}
                    isOpenedSideBar={open}
                    setIsOpenedSideBar={setOpen}
                  />
                ))
            )}
          </List>
        </Drawer>
      </ClickAwayListener>
    </>
  );
};

export default SideBarContainer;
