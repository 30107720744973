import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import ModuleDashboard from "../../../components/dashboard";
import { getUserMenuList } from "../../../../application/selectors/user";
import { useLoaderData } from "react-router-dom";
import DefaultApiService from "../../../../infrastructure/api/default";
import BreadcrumbsComponent from "../../../components/shared/breadcrumbs";
const JPManagementPage = () => {


      
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      DefaultApiService.getApi("/JP/Management/Home/GetData", {}, true).then((res) => {
        if (res?.data?.dataList) {
          setDataList(res?.data?.dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return (
    


    <Grid container className="home-container" spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>
      <Grid item xs={12} md={12}>
        <ModuleDashboard
           
          blockList={dataList}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};


export default JPManagementPage;
 



 