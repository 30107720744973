import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserMenuList } from "../application/slices/user.js";

import DefaultApiService from "../infrastructure/api/default.js";

// Layout
// import Layout from "./components/Layout";
import Layout from "./components/layout/index.js";
import NoMatch from "./pages/NoMatch";

// Auth
import AuthLayout from "./pages/auth";
import LoginPage from "./pages/auth/Login";
import SignupPage from "./pages/auth/Signup";

import Home from "./pages/Home";
import LandingPage from "./pages/Landing";

import PrivateRoute from "./components/layout/PrivateRoute.js";

// *********** [ User Management ] ***********
import UserManagementService from "../infrastructure/api/userManagement";
import UserManagementPage from "./pages/user-management/index.js";
import RoleInfoPage from "./pages/user-management/role-info/index.js";
import RoleInfoModifyPage from "./pages/user-management/role-info/RoleInfoModify.js";
import UserInfoPage from "./pages/user-management/user-info/index.js";
import UserInfoModifyPage from "./pages/user-management/user-info/UserInfoModify.js";

// *********** [ Content Management ] ***********
import CMSPage from "./pages/cms/index.js";
import CMSManagementPage from "./pages/cms/management/index.js";
import CMSCategoryPage from "./pages/cms/management/category/index.js";
import CMSCategoryModifyPage from "./pages/cms/management/category/CategoryModify.js";
import CMSContentPage from "./pages/cms/management/content/index.js";
import CMSContentModifyPage from "./pages/cms/management/content/ContentModify.js";
import CMSGlobalContentPage from "./pages/cms/management/global-content/index.js";
import CMSGlobalContentModifyPage from "./pages/cms/management/global-content/GlobalContentModify.js";
import CMSProductCategoryPage from "./pages/cms/management/product-category/index.js";
import CMSProductCategoryModifyPage from "./pages/cms/management/product-category/ProductCategoryModify.js";
import CMSProductPage from "./pages/cms/management/product/index.js";
import CMSProductModifyPage from "./pages/cms/management/product/ProductModify.js";
import CMSCustomerPage from "./pages/cms/management/customer/index.js";
import CMSCustomerModifyPage from "./pages/cms/management/customer/CustomerModify.js";
import CMSLicensePage from "./pages/cms/management/license/index.js";
import CMSLicenseModifyPage from "./pages/cms/management/license/LicenseModify.js";

import SocialMediaPage from "./pages/cms/management/social-media/index.js";
import SocialMediaModifyPage from "./pages/cms/management/social-media/SocialMediaModify.js";

import FooterLinkPage from "./pages/cms/management/footer-link/index.js";
import  FooterLinkModifyPage from "./pages/cms/management/footer-link/FooterLinkModify.js";

// *********** [ YeksaPay ] ***********
import YSAPAYPage from "./pages/yeksapay/index.js";
import PayManagementPage from "./pages/yeksapay/management/index.js";
import TerminalPage from "./pages/yeksapay/management/terminal/index.js";
import TerminalModifyPage from "./pages/yeksapay/management/terminal/TerminalModify.js";
import PayerPage from "./pages/yeksapay/management/payer/index.js";
import PayerModifyPage from "./pages/yeksapay/management/payer/PayerModify.js";
import GatwayTransactionPage from "./pages/yeksapay/management/reports/GatwayTransaction.js";

// *********** [ JP Group ] ***********
import JPPage from "./pages/jp/index.js";
import JPManagementPage from "./pages/jp/management/index.js";
import ContentPage from "./pages/jp/management/content/index.js";
import ContentModifyPage from "./pages/jp/management/content/ContentModify.js";
import SliderPage from "./pages/jp/management/slider/index.js";
import SliderModifyPage from "./pages/jp/management/slider/SliderModify.js";
import AppVersionPage from "./pages/jp/management/app-version/index.js";
import AppVersionModifyPage from "./pages/jp/management/app-version/AppVersionModify.js";

// *********** [ Shop Management ] ***********
import SHOPPage from "./pages/shop/index.js";
import SHOPManagementPage from "./pages/shop/management/index.js";
import SHOPBrandPage from "./pages/shop/management/brand/index.js";
import SHOPBrandModifyPage from "./pages/shop/management/brand/BrandModify.js";
import SHOPCategoryPage from "./pages/shop/management/category/index.js";
import SHOPCategoryModifyPage from "./pages/shop/management/category/CategoryModify.js";
import ProductPage from "./pages/shop/management/product/index.js";
import ProductModifyPage from "./pages/shop/management/product/ProductModify.js";
import CompletedBuyModifyPage from "./pages/shop/management/completedBuy/CompletedBuyModify.js";
import CompletedBuyPage from "./pages/shop/management/completedBuy/index.js";
import PurchaseStepModifyPage from "./pages/shop/management/purchaseStep/PurchaseStepModify.js";
import PurchaseStepPage from "./pages/shop/management/purchaseStep/index.js";
import InvoiceCheckModifyPage from "./pages/shop/management/invoiceCheck/InvoiceCheckModify.js";
import InvoiceCheckPage from "./pages/shop/management/invoiceCheck/index.js";
import DiscountDefinitionPage from "./pages/shop/management/discount/index.js";
import DiscountDefinitionModifyPage from "./pages/shop/management/discount/DiscountDefinitionModify.js";

// ==== Account ====
import ResetPasswordPage from "./pages/account/ResetPassword.js";

const Index = () => {
  const dispatch = useDispatch();

  const loaderDashboard = async ({ url }) => {
    try {
      const res = await DefaultApiService.getDashboard(url);
      if (res.status === 404) {
        throw new Response("Not Found", { status: 404 });
      }

      return res.data || [];
    } catch (error) {
      return null;
    }
  };

  const loaderGridByParentID = async ({params , url }) => {
    try {
      let treeId = params?.treeId
      const res = await DefaultApiService.getListApiByParentId(url  ,treeId);
      if (res.status === 404) {
        throw new Response("Not Found", { status: 404 });
      }

      return res.data || [];
    } catch (error) {
      return null;
    }
  };

  const loaderGrid = async ({ url }) => {
    try {
      const res = await DefaultApiService.getListApi(url);
      if (res.status === 404) {
        throw new Response("Not Found", { status: 404 });
      }

      return res.data || [];
    } catch (error) {
      return null;
    }
  };

  const loaderById = async ({ params, initValue, url }) => {
    let id = params?.id,
      result = {};

    if (id && id === "create") {
      id = 0;
      result = initValue;
    }

    const res = await DefaultApiService.getOneApi(url, id);

    if (res.status === 404) {
      throw new Response("Not Found", { status: 404 });
    }

    const sendData = { ...res.data, ...result };
     

    return sendData;
  };

  const loaderReport = async ({ url }) => {
    try {
      const res = await DefaultApiService.getApi(url + "/GetFilterData");
      if (res.status === 404) {
        throw new Response("Not Found", { status: 404 });
      }

      return res.data || [];
    } catch (error) {
      return null;
    }
  };

  const router = createBrowserRouter([
    // AUTH
    {
      path: "/auth",
      element: <AuthLayout />,
      children: [
        {
          path: "login",
          element: <LoginPage />,
        },
        {
          path: "signup",
          element: <SignupPage />,
        },
      ],
    },
    // Landing
    {
      path: "/landing",
      element: <LandingPage />,
    },
    // Private Route
    {
      path: "/",
      element: (
        <PrivateRoute>
          <Layout />
        </PrivateRoute>
      ),
      loader: async () => {
        dispatch(setUserMenuList());
        return [];
      },
      children: [
        {
          path: "*",
          element: <NoMatch />,
        },
        {
          path: "",
          element: <Home />,
        },
        {
          path: "account",
          children: [
            {
              path: "resetpassword",
              element: <ResetPasswordPage />,
            },
          ],
        },
        // UM
        {
          path: "UM",
          children: [
            {
              path: "",
              element: <UserManagementPage />,
            },
            {
              path: "Yeksa/RoleInfo",
              element: <RoleInfoPage />,
              loader: async () => {
                try {
                  const res = await UserManagementService.getRoleInfoListApi();
                  if (res.status === 404) {
                    throw new Response("Not Found", { status: 404 });
                  }

                  return res.data;
                } catch (error) {
                  return null;
                }
              },
            },
            {
              path: "Yeksa/RoleInfo/:id",
              element: <RoleInfoModifyPage />,
              loader: async ({ params }) => {
                let id = params.id,
                  result = {};

                if (params.id && params.id === "create") {
                  id = null;
                  result = {
                    role: {
                      isActive: false,
                      roleDescription: "",
                      roleName: "",
                    },
                  };
                }

                const res = await UserManagementService.getRoleInfoByIdApi(id);

                if (res.status === 404) {
                  throw new Response("Not Found", { status: 404 });
                }
                // console.log({ ...res.data, ...result });
                return { ...res.data, ...result };
              },
            },
            {
              path: "Yeksa/userinfo",
              element: <UserInfoPage />,
              loader: async () => {
                try {
                  const res = await UserManagementService.getUserInfoListApi();
                  if (res.status === 404) {
                    throw new Response("Not Found", { status: 404 });
                  }

                  return res.data || [];
                } catch (error) {
                  return null;
                }
              },
            },
            {
              path: "Yeksa/userinfo/:id",
              element: <UserInfoModifyPage />,
              loader: async ({ params }) => {
                let id = params.id,
                  result = {};

                if (params.id && params.id === "create") {
                  id = 0;
                  result = {
                    userInfo: {
                      email: "",
                      firstName: "",
                      isActive: true,
                      lastName: "",
                      mobile: "",
                      roleId: "",
                      roleName: "",
                      userName: "",
                      password: "",
                      confirmPassword: "",
                    },
                  };
                }

                const res = await UserManagementService.getUserInfoByIdApi(id);

                if (res.status === 404) {
                  throw new Response("Not Found", { status: 404 });
                }
                console.log("getUserInfoByIdApi", { ...res.data, ...result });
                return { ...res.data, ...result };
              },
            },
          ],
        },

        // CMS
        {
          path: "CMS",
          children: [
            {
              path: "",
              element: <CMSPage />,
            },
            {
              path: "Management",
              element: <CMSManagementPage />,
              loader: async () =>
                await loaderDashboard({
                  url: "CMS/Management",
                }),
            }
            ,
            {
              path: "Management/Category",
              element: <CMSCategoryPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/Category",
                });
              },
            },
            {
              path: "Management/Category/:id",
              element: <CMSCategoryModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    category: {
                      title: "",
                      description: "",
                      isActive: true,
                    },
                  },
                  url: "CMS/Management/Category",
                });
              },
            },

            
            {
              path: "Management/Content",
              element: <CMSContentPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/Content",
                });
              },
            },
            {
              path: "Management/Content/:id",
              element: <CMSContentModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    content: {
                      id: 0,
                      title: "",
                      subTitle: "",
                      url: "",
                      seoTitle: "",
                      seoName: "",
                      seoKeywords: "",
                      seoDescription: "",
                      content: "",
                      source: "",
                      isActive: true,
                      description: "",
                      categoryTitle: "",
                      categoryId: 0,
                      updateOn: "",
                    },
                  },
                  url: "CMS/Management/Content",
                });
              },
            },

            
            {
              path: "Management/GlobalContent",
              element: <CMSGlobalContentPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/GlobalContent",
                });
              },
            },
            {
              path: "Management/GlobalContent/:id",
              element: <CMSGlobalContentModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    globalContent: {
                      id: 0,
                      aboutUs:"",
                      contact:"",
                      services:"",
                      updateOn: "",
                    },
                  },
                  url: "CMS/Management/GlobalContent",
                });
              },
            },



            {
              path: "Management/ProductCategory",
              element: <CMSProductCategoryPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/ProductCategory",
                });
              },
            },
            {
              path: "Management/ProductCategory/:id",
              element: <CMSProductCategoryModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    productCategory: {
                      title: "",
                      description: "",
                      isActive: true,
                    },
                  },
                  url: "CMS/Management/ProductCategory",
                });
              },
            },
            {
              path: "Management/Product",
              element: <CMSProductPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/Product",
                });
              },
            },
            {
              path: "Management/Product/:id",
              element: <CMSProductModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    product: {
                      id: 0,
                      title: "",
                      subTitle: "",
                      imageUrl: "",
                      isActive: true,
                      description: "",
                      categoryTitle: "",
                      categoryId: 0,
                      updateOn: "",
                      lightLink: "",
                    },
                  },
                  url: "CMS/Management/Product",
                });
              },
            },
            {
              path: "Management/License",
              element: <CMSLicensePage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/License",
                });
              },
            },
            {
              path: "Management/License/:id",
              element: <CMSLicenseModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    license: {
                      title: "",
                      description: "",
                      image: "",
                      isActive: true,
                    },
                  },
                  url: "CMS/Management/License",
                });
              },
            },

            {
              path: "Management/Customer",
              element: <CMSCustomerPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/Customer",
                });
              },
            },
            {
              path: "Management/Customer/:id",
              element: <CMSCustomerModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    customer: {
                      title: "",
                      description: "",
                      logo: "",
                      isActive: true,
                    },
                  },
                  url: "CMS/Management/Customer",
                });
              },
            }
            ,

            {
              path: "Management/SocialMedia",
              element: <SocialMediaPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/SocialMedia",
                });
              },
            },
            {
              path: "Management/SocialMedia/:id",
              element: <SocialMediaModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    socialMedia: {
                      title: "",
                      link: "",
                      description: "",
                      logo: "",
                      isActive: true,
                    },
                  },
                  url: "CMS/Management/SocialMedia",
                });
              },
            },





            ,

            {
              path: "Management/FooterLink",
              element: <FooterLinkPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "CMS/Management/FooterLink",
                });
              },
            },
            {
              path: "Management/FooterLink/:id",
              element: <FooterLinkModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    footerLink: {
                      title: "",
                      link: "", 
                      isActive: true,
                    },
                  },
                  url: "CMS/Management/FooterLink",
                });
              },
            },



          ],
        },

        // Pay
        {
          path: "YSAPAY",
          children: [
            {
              path: "",
              element: <YSAPAYPage />,
            },
            {
              path: "Management",
              element: <PayManagementPage />,
              loader: async () =>
                await loaderDashboard({
                  url: "YSAPAY/Management",
                }),
            },
            {
              path: "Management/Terminal",
              element: <TerminalPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "YSAPAY/Management/Terminal",
                });
              },
            },
            {
              path: "Management/Terminal/:id",
              element: <TerminalModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    terminal: {
                      title: "",
                      description: "",
                      isActive: true,
                    },
                  },
                  url: "YSAPAY/Management/Terminal",
                });
              },
            },
            {
              path: "Management/Payer",
              element: <PayerPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "YSAPAY/Management/Payer",
                });
              },
            },
            {
              path: "Management/Payer/:id",
              element: <PayerModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    payer: {
                      id: 0,
                      terminalId: 0,
                      payerId: 0,
                      title: "",
                      mobile: "",
                      isActive: false,
                      description: "",
                      updateOn: "",
                    },
                  },
                  url: "YSAPAY/Management/Payer",
                });
              },
            },

            {
              path: "Management/GatwayTransaction",
              element: <GatwayTransactionPage />,
              loader: async () =>
                await loaderReport({
                  url: "YSAPAY/Management/GatwayTransaction",
                }),
            },
          ],
        },

        // JP
        {
          path: "JP",
          children: [
            {
              path: "",
              element: <JPPage />,
            },
            {
              path: "Management",
              element: <JPManagementPage />,
              loader: async () =>
                await loaderDashboard({
                  url: "JP/Management",
                }),
            }
            
            ,
            {
              path: "Management/Content",
              element: <ContentPage />,
              loader: async () => {
                return await loaderGrid({ 
                  url: "JP/Management/Content",
                });
              },
            },
            {
              path: "Management/Content/:treeId",
              element: <ContentPage />,
              loader: async ({ params }) => {
                return await loaderGridByParentID({ params ,
                  url: "JP/Management/Content",
                });
              },
            },
            {
              path: "Management/Content/edit/:id/:treeId",
              element: <ContentModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    tree: {
                      treeId: null,
                      title: "",
                      leveKind: "",
                      description: "",
                      isActive: true,
                      imageUrl: "",
                      fileUrl: "",
                      content: "",
                      price: 0,
                    },
                  },
                  url: "JP/Management/Content",
                });
              },
            },
            {
              path: "Management/Content/create/:id/:treeId",
              element: <ContentModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    tree: {
                       
                      title: "",
                      leveKind: "",
                      description: "",
                      isActive: true,
                      imageUrl: "",
                      fileUrl: "",
                      content: "",
                      price: 0,
                    },
                  },
                  url: "JP/Management/Content",
                });
              },
            }, 
            {
              path: "Management/AppVersion",
              element: <AppVersionPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "JP/Management/AppVersion",
                });
              },
            },
            {
              path: "Management/AppVersion/:id",
              element: <AppVersionModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    appVersion: {
                      
                      title: "",
                      verison: "",
                      changes: "",
                      isActive: false,
                      isForce: false,
                      releaseDate: "",
                      playUrl: "",
                      bazarUrl: "",
                      directUrl: "",
                      mayketUrl: "",
                    },
                  },
                  url: "JP/Management/AppVersion",
                });
              },
            },



            ,
            {
              path: "Management/Slider",
              element: <SliderPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "JP/Management/Slider",
                });
              },
            },
            {
              path: "Management/Slider/:id",
              element: <SliderModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    slider: {
                      id: 0,
                      title: "",
                      imageUrl: "",
                      sliderType: "Link",
                      isActive: true,
                      data: ""
                    },
                  },
                  url: "JP/Management/Slider",
                });
              },
            },
          ],
        },

        // SHOP
        {
          path: "SHOP",
          children: [
            {
              path: "",
              element: <SHOPPage />,
            },
            {
              path: "Management",
              element: <SHOPManagementPage />,
              loader: async () =>
                await loaderDashboard({
                  url: "SHOP/Management",
                }),
            },
            {
              path: "Management/Category",
              element: <SHOPCategoryPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "SHOP/Management/Category",
                });
              },
            },
            {
              path: "Management/Category/:id",
              element: <SHOPCategoryModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    category: {
                      title: "",
                      description: "",
                      isActive: true,
                    },
                  },
                  url: "SHOP/Management/Category",
                });
              },
            },
            {
              path: "Management/Brand",
              element: <SHOPBrandPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "SHOP/Management/Brand",
                });
              },
            },
            {
              path: "Management/Brand/:id",
              element: <SHOPBrandModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    brand: {
                      title: "",
                      logo: "",
                      description: "",
                      isActive: true,
                    },
                  },
                  url: "SHOP/Management/Brand",
                });
              },
            },
            {
              path: "Management/DiscountDefinition",
              element: <DiscountDefinitionPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "SHOP/Management/DiscountDefinition",
                });
              },
            },
            {
              path: "Management/DiscountDefinition/:id",
              element: <DiscountDefinitionModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    discount: {
                      title: "",
                      discountCode: "",
                      percent: "",
                      maxNumber: "",
                      startDateTime: "",
                      endDateTime: "",
                      discountTypes: "",
                      description: "",
                      isActive: true,
                    },
                  },
                  url: "SHOP/Management/DiscountDefinition",
                });
              },
            },

            // Product
            {
              path: "Management/Product",
              element: <ProductPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "SHOP/Management/Product",
                });
              },
            },
            {
              path: "Management/Product/:id",
              element: <ProductModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    product: {
                      id: 0,
                      categoryId: 0,
                      title: "",
                      keywords: "",
                      url: "",
                      isActive: true,
                      description: "",
                      updateOn: "",
                    },
                  },
                  url: "SHOP/Management/Product",
                });
              },
            },
            // CompletedBuy
            {
              path: "Management/CompletedBuy",
              element: <CompletedBuyPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "SHOP/Management/CompletedBuy",
                });
              },
            },
            {
              path: "Management/CompletedBuy/:id",
              element: <CompletedBuyModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    product: {
                      id: 0,
                      price: 0,
                      title: "",
                      keywords: "",
                      url: "",
                      isActive: true,
                      description: "",
                      updateOn: "",
                    },
                  },
                  url: "SHOP/Management/CompletedBuy",
                });
              },
            },
            // PurchaseStep
            {
              path: "Management/PurchaseStep",
              element: <PurchaseStepPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "SHOP/Management/PurchaseStep",
                });
              },
            },
            {
              path: "Management/PurchaseStep/:id",
              element: <PurchaseStepModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    purchaseStep: {
                      id: 0,
                      title: "",
                      priority: 0,
                      stepDescription: "",
                      adminAlertText: "",
                      customerAlertText: "",
                      showToCustomer: true,
                      isActive: true,
                      daysRequired: 0,
                      updateOn: "",
                    },
                  },
                  url: "SHOP/Management/PurchaseStep",
                });
              },
            },

            // InvoiceCheck
            {
              path: "Management/InvoiceCheck",
              element: <InvoiceCheckPage />,
              loader: async () => {
                return await loaderGrid({
                  url: "SHOP/Management/InvoiceCheck",
                });
              },
            },
            {
              path: "Management/InvoiceCheck/:id",
              element: <InvoiceCheckModifyPage />,
              loader: async ({ params }) => {
                return await loaderById({
                  params,
                  initValue: {
                    product: {
                      id: 0,
                      price: 0,
                      title: "",
                      keywords: "",
                      url: "",
                      isActive: true,
                      description: "",
                      updateOn: "",
                    },
                  },
                  url: "SHOP/Management/InvoiceCheck",
                });
              },
            },
          ],
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
};

export default Index;
