import { api, getLocalRefreshToken } from "../configs/axiosConfigs";

const register = async (username, email, password) => {
  const response = await api.request({
    url: `account/signup`,
    method: "POST",
    data: {
      username,
      email,
      password,
    },
  });

  if (response.data.isSuccess) {
    localStorage.setItem("user", JSON.stringify(response.data.data));
  }

  return response.data;
};

const login = async (username, password) => {
  const response = await api.request({
    url: `account/login`,
    method: "POST",
    data: {
      username,
      password,
    },
  });

  if (response.data.isSuccess) {
    localStorage.setItem("user", JSON.stringify(response.data.data));
  }

  return response.data;
};

const refreshToken = async () => {
  try {
    const response = await api.request({
      url: `account/RefreshToken`,
      method: "POST",
      data: {
        RefreshToken: getLocalRefreshToken(),
      },
    });

    if (response.data.isSuccess) {
      localStorage.setItem("user", JSON.stringify(response.data.data));
    }
    return response.data;
  } catch (error) {
    localStorage.removeItem("user");
  }
};

const logout = () => {
  localStorage.removeItem("user");
};

const AuthService = {
  register,
  login,
  logout,
  refreshToken,
};

export default AuthService;
