import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { visuallyHidden } from "@mui/utils";
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FiberNewIcon from "@mui/icons-material/FiberNew";

import { toJalaliDate } from "../../../middleware/middleware";
import useCheckPermission from "../../../common/hooks/useCheckPermission";
import LoadingWrapper from "../loading";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: 0,
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
  "&:first-of-type": {
    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
  },
  "&:last-child": {
    borderBottomRightRadius: 10,
    borderTopRightRadius: 10,
  },
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  "&:hover": {
    color: "#ccc",
  },
  "&.Mui-active": {
    color: "#fff",
    fontWeight: "bold",
    ".MuiTableSortLabel-icon": {
      color: "#fff",
    },
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  borderRadius: 10,
  border: 0,
  marginBottom: 16,
  whiteSpace: "nowrap",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#C0D9FF",
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.common.white,
    boxShadow: "0px 12px 21px 2px rgba(218, 230, 249, 0.4)",
  },
  "&:nth-of-type(odd)": {
    backgroundColor: "#e8f1ff",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&.grid-status-danger,&.grid-status-error": {
    "td:first-of-type": {
      borderLeftColor: theme.palette.danger.main,
      borderLeftWidth: 6,
      borderLeftStyle: "solid",
    },
  },

  "&.grid-status-success": {
    "td:first-of-type": {
      borderLeftColor: theme.palette.success.main,
      borderLeftWidth: 6,
      borderLeftStyle: "solid",
    },
  },
  "&.grid-status-warning": {
    "td:first-of-type": {
      borderLeftColor: theme.palette.warning.main,
      borderLeftWidth: 6,
      borderLeftStyle: "solid",
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  const aValue = orderBy === "ردیف" ? parseInt(a[orderBy]) : a[orderBy];
  const bValue = orderBy === "ردیف" ? parseInt(b[orderBy]) : b[orderBy];

  if (bValue < aValue) {
    return -1;
  }
  if (bValue > aValue) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells, actions, showIndex } =
    props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <StyledTableRow>
        {showIndex ? (
          <StyledTableCell align={"center"}>
            <StyledTableSortLabel
              active={orderBy === "row_index"}
              direction={orderBy === "row_index" ? order : "asc"}
              onClick={createSortHandler("row_index")}
            >
              ردیف
              {orderBy === "row_index" ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </StyledTableCell>
        ) : null}

        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <StyledTableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </StyledTableSortLabel>
          </StyledTableCell>
        ))}
        {Array.isArray(actions) && actions.length > 0 ? (
          <StyledTableCell
            align={"center"}
            style={{
              position: "sticky",
              left: 0,
            }}
          >
            عملیات
          </StyledTableCell>
        ) : null}
      </StyledTableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  headCells: PropTypes.any,
  actions: PropTypes.any,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function EnhancedTable({
  rowKey,
  rows,
  headCells,
  actions,
  pageSize = 5,
  showFilter = true,
  showIndex = true,
  loading = false,
  getRowStatus = undefined,
  showPaging = true,
}) {
  const [rowsData, setRowsData] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);
  const [searched, setSearched] = React.useState("");

  React.useEffect(() => {
    if (Array.isArray(rows) && rows.length > 0) {
      const newData = rows.map((x, index) => ({ row_index: index + 1, ...x }));
      setRowsData(newData);
    } else {
      setRowsData([]);
    }
  }, [rows]);

  // Add Debounce For Search In Table
  React.useEffect(() => {
    const timeOutId = setTimeout(() => {
      const filteredRows = rows
        .filter((row) => {
          let checked = false;
          for (let i = 0; i < headCells.length; i++) {
            const header = headCells[i];

            switch (header?.type) {
              case "date":
                break;
              case "boolean":
                const str = row[header.id] ? "فعال" : "غیرفعال";
                checked = str.toLowerCase().includes(searched.toLowerCase());
                break;
              default:
                if (row[header.id])
                  checked = row[header.id]
                    .toString()
                    .toLowerCase()
                    .includes(searched.toLowerCase());
                break;
            }

            if (checked) break;
          }

          return checked;
        })
        .map((x, index) => ({ row_index: index + 1, ...x }));

      setRowsData(filteredRows);
    }, 500);

    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searched]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowsData.length) : 0;

  const renderCellTemplate = (row, head) => {
    switch (head.type) {
      case "number":
        return !Number.isNaN(row[head.id]) ? row[head.id] : "";

      case "date":
        return row[head.id] ? toJalaliDate(row[head.id]) : "";

      case "image" : 
        return <img src={row[head.id]} alt="Preview"   style={{
          width: "100%",
          height: "auto",
          maxWidth: "100px",
          maxHeight: "100px",
        }}/>;  

      case "boolean":
        return row[head.id] ? "فعال" : "غیرفعال";

      default:
        return row[head.id] || "-";
    }
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
  };

  const cancelSearch = () => {
    requestSearch("");
  };

  return (
    <Box sx={{ width: "100%" }}>
      {useCheckPermission("show") ? (
        <>
          {showFilter ? (
            <TextField
              value={searched}
              onChange={(e) => requestSearch(e.target.value)}
              onCancelSearch={() => cancelSearch()}
              size="small"
              variant="outlined"
              placeholder="جستجو..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: searched && (
                  <IconButton size="small" onClick={() => cancelSearch()}>
                    <ClearIcon sx={{ width: 12, height: 12 }} />
                  </IconButton>
                ),
                style: {
                  paddingLeft: searched ? 2 : 10,
                },
              }}
              sx={{ mb: 1 }}
            />
          ) : null}

          {loading ? (
            <LoadingWrapper />
          ) : (
            <TableContainer >
              <Table sx={{ minWidth: 750 }} size={"small"} stickyHeader>
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rowsData.length}
                  headCells={headCells}
                  actions={actions}
                  showIndex={showIndex}
                />
                <TableBody>
                  {Array.isArray(rowsData) && rowsData.length > 0 ? (
                    <>
                      {stableSort(rowsData, getComparator(order, orderBy))
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const isItemSelected = isSelected(row[rowKey]);
                          // const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <StyledTableRow
                              key={row[rowKey] || `row-${index}`}
                              hover
                              onClick={(event) => handleClick(event, row.name)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              selected={isItemSelected}
                              className={
                                getRowStatus
                                  ? `grid-status-${getRowStatus(row)}`
                                  : ""
                              }
                              dir="rtl"
                            >
                              {showIndex ? (
                                <StyledTableCell
                                  scope="row"
                                  align="center"
                                  sx={{ position: "relative" }}
                                >
                                  {row.row_index}

                                  {row.isNew ? (
                                    <FiberNewIcon
                                      color="primary"
                                      sx={{ position: "absolute", left: 2 }}
                                    />
                                  ) : null}
                                </StyledTableCell>
                              ) : null}

                              {headCells.map((head, index) => (
                                <StyledTableCell
                                  key={`StyledTableCell-${index}`}
                                  align="center"
                                >
                                  {renderCellTemplate(row, head)}
                                </StyledTableCell>
                              ))}

                              {Array.isArray(actions) && actions.length > 0 ? (
                                <StyledTableCell
                                  align={"center"}
                                  style={{
                                    position: "sticky",
                                    left: 0,
                                    background: "inherit",
                                  }}
                                >
                                  {actions.map(
                                    (
                                      { icon, onClick, color = "" },
                                      actionIndex
                                    ) => (
                                      <IconButton
                                        key={`action-${actionIndex}`}
                                        color={color}
                                        onClick={() => {
                                          onClick(row);
                                        }}
                                      >
                                        {icon}
                                      </IconButton>
                                    )
                                  )}
                                </StyledTableCell>
                              ) : null}
                            </StyledTableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: 53 * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </>
                  ) : (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={10}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            mb: 4,
                          }}
                        >
                          <DashboardIcon
                            sx={{ fontSize: "8rem", my: 10 }}
                            color="gray"
                          />
                          <Typography
                            color="gray"
                            variant="h6"
                            fontWeight="bold"
                          >
                            داده ای جهت نمایش وجود ندارد
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}

          {showPaging ? (
            <TablePagination
              rowsPerPageOptions={[
                10, 50, 100, 500,
                //  { label: "همه", value: -1 }
              ]}
              component="div"
              count={rowsData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              lang="fa-IR"
              variant="outlined"
              shape="rounded"
              ActionsComponent={TablePaginationActions}
            />
          ) : null}
        </>
      ) : null}
    </Box>
  );
}
