import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserMenuList } from "../../../application/selectors/user";

const useGetPageInfo = () => {
  const { pathname } = useLocation();
  const menuList = useSelector(getUserMenuList);
  const [pageInfo, setPageInfo] = useState({});

  const onFindPageItem = (items) => {
    let data = {};
    items.forEach((menu) => {
      if (menu.level !== 2) {
        data[`${menu.link}`] = menu.title;
      }

      if (menu.link === pathname) {
        // console.log("menu", menu);
        return setPageInfo(menu);
      } else {
        if (Array.isArray(menu.child)) {
          return onFindPageItem(menu.child);
        }
      }
    });
  };

  useEffect(() => {
    if (Array.isArray(menuList) && menuList.length > 0) {
      onFindPageItem(menuList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuList]);

  return pageInfo;
};

export default useGetPageInfo;
