import React from "react";
import { Divider, Drawer, List, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";

import MobileMenuItem from "./MobileMenuItem";
import { getUserMenuList } from "../../../application/selectors/user";

const MobileSidebar = ({ window, open, onClose }) => {
  const modules = useSelector(getUserMenuList);

  const drawerWidth = 240;
  const container =
    window !== undefined ? () => window().document.body : undefined;

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>

      <Divider />
      <List>
        <MobileMenuItem
          menu={{ title: "داشبورد", link: "/", icon: "Home" }}
          onCloseSidebar={onClose}
        />

        {React.Children.toArray(
          modules.map((menu) => (
            <MobileMenuItem
              key={menu.id}
              menu={menu}
              onCloseSidebar={() => onClose(true)}
            />
          ))
        )}
      </List>
    </Box>
  );

  return (
    <Box component="nav">
      <Drawer
        container={container}
        variant="temporary"
        open={open}
        onClose={onClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default MobileSidebar;
