import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import DefaultApiService from "../../../../../infrastructure/api/default";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CMSService from "../../../../../infrastructure/api/cms";
import CMSCategorySelect from "../../../../components/cms/CategorySelect";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const validationSchema = Yup.object().shape({
  aboutUs: Yup.string().required("لطفا این فیلد را پر کنید."),
  contact: Yup.string().required("لطفا این فیلد را پر کنید."),
  services: Yup.string().required("لطفا این فیلد را پر کنید."),
});

const CMSGlobalContentModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    navigate("/CMS/Management/GlobalContent");
  };

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try {
      const res = await CMSService.modifyGlobalContentApi(sendData);

      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          

          <CardContent>
            <Formik
              initialValues={data.globalContent}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>


<CardHeader
            title={
                 "درباره ما"
            }
          />
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12} lg={12}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.aboutUs}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("aboutUs", data);
                        }}
                      />
                      {errors.aboutUs && touched.aboutUs && (
                        <div>{errors.aboutUs}</div>
                      )}
                    </Grid>



                    <CardHeader
            title={
                 "تماس با ما"
            }
          />


                    <Grid item xs={12} md={12} lg={12}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.contact}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("contact", data);
                        }}
                      />
                      {errors.contact && touched.contact && (
                        <div>{errors.contact}</div>
                      )}
                    </Grid>


                    <CardHeader
            title={
                 "خدمات"
            }
          />


                    <Grid item xs={12} md={12} lg={12}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.services}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("services", data);
                        }}
                      />
                      {errors.services && touched.services && (
                        <div>{errors.services}</div>
                      )}
                    </Grid>




                    <CardHeader
            title={
                 "متن پاورقی"
            }
          />


                    <Grid item xs={12} md={12} lg={12}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.footer}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("footer", data);
                        }}
                      />
                      {errors.footer && touched.footer && (
                        <div>{errors.footer}</div>
                      )}
                    </Grid>

 
                    <CardHeader
            title={
                 "Mission Vision"
            }
          />
 
                    <Grid item xs={12} md={12} lg={12}>
                      <CKEditor
                        editor={ClassicEditor}
                        data={values.missionVision}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setFieldValue("missionVision", data);
                        }}
                      />
                      {errors.missionVision && touched.missionVision && (
                        <div>{errors.missionVision}</div>
                      )}
                    </Grid>
  
                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={onNavigateToListPage}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                
                
                
                
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CMSGlobalContentModifyPage;
