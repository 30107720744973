import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
  ReferenceArea,
} from "recharts";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ChartProvider from ".";
import LoadingWrapper from "../loading";

const LineChartCustom = ({
  title,
  xAxis = "x",
  xType = undefined,
  xAxisTitle = "",
  yAxisTitle = "",
  data = [],
  series = [],
  loading = false,
  interval = 1,
  colors = ChartProvider.colors,
  showBrush = false,
}) => {
  const [chartProps, setChartProps] = useState(
    series.reduce(
      (a, b) => {
        a[b] = false;
        return a;
      },
      { hover: null }
    )
  );
  const [state, setState] = useState({
    data: data,
    left: "dataMin",
    right: "dataMax",
    refAreaLeft: "",
    refAreaRight: "",
    top: "dataMax+1",
    bottom: "dataMin-1",
    animation: true,
  });

  const handleLegendMouseEnter = (e) => {
    if (!chartProps[e.dataKey]) {
      setChartProps({ ...chartProps, hover: e.dataKey });
    }
  };

  const handleLegendMouseLeave = (e) => {
    setChartProps({ ...chartProps, hover: null });
  };

  const selectBar = (e) => {
    setChartProps({
      ...chartProps,
      [e.dataKey]: !chartProps[e.dataKey],
      hover: null,
    });
  };

  // ZOOM
  // const getAxisYDomain = (from, to, ref, offset) => {
  //   const refData = data.slice(from - 1, to);
  //   let [bottom, top] = [refData[0][ref], refData[0][ref]];

  //   refData.forEach((d) => {
  //     if (d[ref] > top) top = d[ref];
  //     if (d[ref] < bottom) bottom = d[ref];
  //   });

  //   return [(bottom | 0) - offset, (top | 0) + offset];
  // };

  const zoom = () => {
    let { refAreaLeft, refAreaRight } = state;
    const { data } = state;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setState((old) => ({
        ...old,
        refAreaLeft: "",
        refAreaRight: "",
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    // yAxis domain
    // const [bottom, top] = getAxisYDomain(refAreaLeft, refAreaRight, "cost", 1);
    // const [bottom2, top2] = getAxisYDomain(
    //   refAreaLeft,
    //   refAreaRight,
    //   "impression",
    //   50
    // );

    setState(() => ({
      refAreaLeft: "",
      refAreaRight: "",
      data: data.slice(),
      left: "dataMin+3",
      right: "dataMax-3",
      top: "dataMax+1",
      bottom: "dataMin-1",
    }));
  };

  // const zoomOut = () => {
  //   const { data } = state;
  //   setState(() => ({
  //     data: data.slice(),
  //     refAreaLeft: "",
  //     refAreaRight: "",
  //     left: "dataMin",
  //     right: "dataMax",
  //     top: "dataMax+1",
  //     bottom: "dataMin",
  //     top2: "dataMax+50",
  //     bottom2: "dataMin+50",
  //   }));
  // };

  return (
    <Grid container style={{ direction: "ltr", height: showBrush ? 400 : 368 }}>
      {loading ? (
        <Grid
          item
          xs={12}
          md={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <LoadingWrapper />
        </Grid>
      ) : Array.isArray(data) && data.length > 0 ? (
        <>
          <Grid item xs={12} md={12}>
            <Typography textAlign={"center"} variant="h6" fontWeight={"bold"}>
              {title}
            </Typography>

            {/* <Button onClick={zoomOut} variant="outlined" size="small">
              Zoom Out
            </Button> */}
          </Grid>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={364}
              data={data}
              margin={{
                top: 16,
                right: 0,
                left: 0,
                bottom: series && series.length > 1 ? 24 : 16,
              }}
              onMouseDown={(e) => {
                if (e?.activeLabel) {
                  setState((old) => ({ ...old, refAreaLeft: e.activeLabel }));
                }
              }}
              onMouseMove={(e) => {
                if (e?.activeLabel && state.refAreaLeft) {
                  setState((old) => ({ ...old, refAreaRight: e.activeLabel }));
                }
              }}
              onMouseUp={zoom}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xAxis}
                label={{
                  value: xAxisTitle || "",
                  style: { textAnchor: "middle" },
                  position: "bottom",
                  offset: 36,
                }}
                angle={-15}
                fontSize={10}
                interval={interval}
                textAnchor="end"
                allowDataOverflow
                domain={[state.left, state.right]}
                type={xType}
                tick={true}
              />
              {showBrush && (
                <Brush dataKey={xAxis} height={28} stroke="#8884d8" />
              )}
              <YAxis
                label={{
                  value: yAxisTitle || "",
                  style: { textAnchor: "middle" },
                  angle: -90,
                  position: "left",
                  offset: -8,
                }}
              />
              <Tooltip />
              {series && series.length > 1 ? (
                <Legend
                  onClick={selectBar}
                  onMouseOver={handleLegendMouseEnter}
                  onMouseOut={handleLegendMouseLeave}
                  verticalAlign="top"
                  //verticalAlign="bottom"
                  // wrapperStyle={{ lineHeight: "40px" }}
                />
              ) : null}
              {series.map((ser, index) => (
                <Line
                  key={index}
                  dataKey={ser}
                  type={"monotone"}
                  stroke={ChartProvider.renderColor(colors, index)}
                  activeDot={{ r: 4 }}
                  hide={chartProps[ser] === true}
                  strokeOpacity={Number(
                    chartProps.hover === ser || !chartProps.hover ? 1 : 0.4
                  )}
                  strokeWidth={2}
                />
              ))}

              {state.refAreaLeft && state.refAreaRight ? (
                <ReferenceArea
                  x1={state.refAreaLeft}
                  x2={state.refAreaRight}
                  strokeOpacity={0.3}
                />
              ) : null}
            </LineChart>
          </ResponsiveContainer>
        </>
      ) : (
        <Grid
          item
          xs={12}
          md={12}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <DashboardIcon sx={{ fontSize: "8rem", mb: 3 }} color="gray" />
          <Typography color="gray" variant="h6" fontWeight="bold">
            داده ای جهت نمایش وجود ندارد
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default LineChartCustom;
