import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";

import { ModalTransition } from "./Config";
import { useState } from "react";

const DeleteConfirm = ({
  open,
  onClose,
  onSubmit,
  message = "برای حذف داده اطمینان دارید؟",
  children,
}) => {
  const [loading, setLoading] = useState(false);

  const onSubmitAction = async () => {
    try {
      const res = await onSubmit();
      if (res.isSuccess) {
        toast.success(res.message);
        onClose(true);
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error("خطایی رخ داده است.");
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={ModalTransition}
      keepMounted
      onClose={onClose}
      maxWidth={"sm"}
      className="dialog-wrapper danger"
    >
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={12}>
            <IconButton onClick={onClose}>
              <ClearIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={12} className="icon-wrapper">
            <DeleteIcon className="icon" />
          </Grid>

          <Grid item xs={12} md={12} className="text">
            {message}
          </Grid>

          {children ? children : null}
        </Grid>
      </DialogContent>

      <DialogActions className="buttons-wrapper">
        <Button
          variant="outline"
          size="large"
          className="cancel-button"
          onClick={onClose}
        >
          انصراف
        </Button>

        <Button
          variant="contain"
          size="large"
          className="submit-button"
          onClick={onSubmitAction}
          disabled={loading}
        >
          {loading && <CircularProgress size={16} color="inherit" />}
          حذف
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirm;
