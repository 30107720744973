import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  isShowModuleSideBar: true,
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { loading: action.payload };
    },
    setIsShowModuleSideBar: (state, action) => {
      state.isShowModuleSideBar = action.payload;
    },
  },
});

const { reducer, actions } = uiSlice;

export const { setLoading, setIsShowModuleSideBar } = actions;
export default reducer;
