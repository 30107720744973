import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Logout,
  Password,
  Person,
  Settings,
  FormatIndentDecrease,
  FormatIndentIncrease,
} from "@mui/icons-material";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import { ReactComponent as NotificationIcon } from "../../assets/icons/notification.svg";
import IconComponent from "../shared/icon";
import LogoIcon from "../../assets/images/logo-icon.png";
import MobileSidebar from "./MobileSideBar";
import { getAuthSlice } from "../../../application/selectors/auth";
import { logout } from "../../../application/slices/auth";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  height: "4rem",
  display: "flex",
  justifyContent: "center",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const drawerWidth = 240;

const AppBarContainer = ({ open, handleDrawerClose, handleDrawerOpen }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  const { user: currentUser } = useSelector(getAuthSlice);

  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const onLogoutUserAccount = () => {
    setAnchorElUser(null);
    dispatch(logout());
    navigate("/auth/login");
  };

  const onResetPassword = () => {
    handleCloseUserMenu();
    navigate("/account/resetpassword");
  };

  if (!currentUser) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return (
    <>
      <AppBar position="fixed" color="secondary" className="app-bar-container">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={open ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className="desktop-icon"
            id="side-bar-icon"
            sx={{
              mr: 3,
              // display: { xs: "none", md: "block" },
            }}
          >
            {open ? (
              <FormatIndentIncrease sx={{ pointerEvents: "none" }} />
            ) : (
              <FormatIndentDecrease sx={{ pointerEvents: "none" }} />
            )}
          </IconButton>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
            className="mobile-icon"
          >
            <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "flex" },
              alignItems: "center",
            }}
          >
            <img src={LogoIcon} alt="PMS" width={32} height={32} />
                  YEKSA     
          </Typography>
          <Box>
            <IconButton aria-label="delete">
              <ClockIcon />
            </IconButton>

            <IconButton aria-label="delete">
              <Badge
                badgeContent={1}
                color="primary"
                variant="dot"
                invisible={false}
              >
                <NotificationIcon />
              </Badge>
            </IconButton>

            <Button
              onClick={handleOpenUserMenu}
              id="user-menu-app-bar"
              className="user-menu-button-container"
            >
              {currentUser?.firstname}
              <div className="avatar">
                <IconComponent name="user" width="16px" height="16px" />
              </div>
            </Button>

            <Menu
              id="user-menu-app-bar"
              anchorEl={anchorElUser}
              keepMounted
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <ListItemIcon>
                  <Person fontSize="small" />
                </ListItemIcon>
                پروفایل
              </MenuItem>

              <MenuItem onClick={handleCloseUserMenu}>
                <ListItemIcon>
                  <Settings fontSize="small" />
                </ListItemIcon>
                تنظیمات
              </MenuItem>

              <MenuItem onClick={onResetPassword}>
                <ListItemIcon>
                  <Password fontSize="small" />
                </ListItemIcon>
                تغییر کلمه عبور
              </MenuItem>

              <Divider />

              <MenuItem onClick={onLogoutUserAccount} color="primary">
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                خروج
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <MobileSidebar
        open={mobileOpen}
        onClose={(event) => {
          if (event) {
            setMobileOpen((prevState) => false);
          }
        }}
      />
    </>
  );
};

export default AppBarContainer;
