import queryString from "query-string";
import { api, authHeader } from "../configs/axiosConfigs";
import { defineCancelApiObject } from "../configs/axiosUtils";

const DefaultApiService = {
  getApi: async (source, params, cancel = false) => {
    const response = await api.request({
      url: `${source}?${queryString.stringify(params)}`,
      method: "GET",
      headers: authHeader(),
      signal: cancel
        ? cancelApiObject["getApi"].handleRequestCancellation().signal
        : undefined,
    });

    return response?.data;
  },
  getListApi: async (source) => {
    const response = await api.request({
      url: `${source}/GetList`,
      method: "GET",
      headers: authHeader(),
    }); 
    return response.data;
  },
  getListApiByParentId: async (source , treeId) => {
    const response = await api.request({
      url: `${source}/GetListByParentId${treeId ? "?treeId=" + treeId : ""}`,
      method: "GET",
      headers: authHeader(),
    });

    return response.data;
  },

  getOneApi: async (source, id) => {
    const response = await api.request({
      url: `${source}/GetById${id ? "?Id=" + id : ""}`,
      method: "GET",
      headers: authHeader(),
    }); 
    return response.data;
  },
  modifyApi: async (source, data) => {
    const response = await api.request({
      url: `${source}/Modify`,
      method: "Post",
      data: data,
      headers: authHeader(),
    });

    return response.data;
  },
  postApi: async (source, data) => {
    const response = await api.request({
      url: `${source}`,
      method: "Post",
      data: data,
      headers: authHeader(),
    });

    return response.data;
  },
  deleteOneApi: async (source, data) => {
    const response = await api.request({
      url: `${source}/Delete`,
      method: "Delete",
      data,
      headers: authHeader(),
    });

    return response.data;
  },
  getDashboard: async (source) => {
    const response = await api.request({
      url: `${source}/Home/GetData`,
      method: "Get",
      headers: authHeader(),
    });

    return response.data;
  },
  uploadFile: async (file, moduleName) => {
    let formData = new FormData();
    formData.append("File", file, file.name);
    formData.append("ModuleName", moduleName);

    const response = await api.request({
      url: "FileUploader/Upload",
      method: "Post",
      data: formData,
      headers: authHeader(),
    });

    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(DefaultApiService);

export default DefaultApiService;
