import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { Card, CardContent, Grid } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { toJalaliFullDate } from "../../../middleware/middleware";

const Stat = ({
  type = "primary",
  value,
  unit,
  label,
  date,
  dateLabel,
  isTime = false,
  infoList = [],
  hasShowMore = false,
  showMore = {},
}) => {
  return (
    <Card>
      <CardContent className={clsx("stat-container", type)}>
        <Grid container>
          <Grid item xs={12} md={12} className="header-container">
            <span className="value">{value}</span>
            <sub className="unit">{unit}</sub>
          </Grid>

          <Grid item xs={12} md={12} className="label-container">
            {label}
          </Grid>

          {React.Children.toArray(
            infoList.map((info) => (
              <Grid item xs={12} md={12} className="info-container">
                <div className="info-title">{info.title}</div>
                <div className="info-value">
                  {info.value} <sub className="info-unit">{info.unit}</sub>
                </div>
              </Grid>
            ))
          )}

          {date ? (
            <Grid item xs={12} md={12} className="date-container">
              <div className="label">
                {isTime ? <AccessTimeIcon /> : <CalendarMonthIcon />}

                {dateLabel}
              </div>
              <div className="date">
                {isTime ? date : toJalaliFullDate(date)}
              </div>
            </Grid>
          ) : null}

          {hasShowMore ? (
            <Grid item xs={12} md={12}>
              <Link to={showMore.link}>
                <div className="show-more-container">
                  {showMore.text}
                  <KeyboardArrowLeftIcon />
                </div>
              </Link>
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default Stat;
