import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik"; 
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs"; 
  
import DefaultApiService from "../../../../../infrastructure/api/default"; 

const validationSchema = Yup.object().shape({
 
  title: Yup.string().required("لطفا این فیلد را پر کنید."),  
  terminalCode: Yup.number().required("لطفا این فیلد را پر کنید."),  
  email: Yup.string().required("لطفا این فیلد را پر کنید."),  
  mobile: Yup.number().required("لطفا این فیلد را پر کنید."),   
  isActive:  Yup.bool(),
  description: Yup.string().required("لطفا این فیلد را پر کنید."),  
 
});

const TerminalModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    navigate("/YSAPAY/Management/Terminal");
  };
   
   
 

 


  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try {  
       
      const res = await DefaultApiService.modifyApi(
        "YSAPAY/Management/Terminal",
        sendData
      );
     
         

      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create" ? `ویرایش ${data?.terminal?.title}` : "ایجاد"
            }
          />

          <CardContent>
            <Formik
              initialValues={data.terminal}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,                
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
 
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="عنوان"
                        variant="outlined"
                        fullWidth
                        name="title"
                        type="string"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title && touched.title}
                        helperText={
                          errors.title && touched.title && errors.title
                        }
                      />
                    </Grid> 

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="نام کاربری مدیر"
                        variant="outlined"
                        fullWidth
                        name="ownerUserName"
                        type="string"
                        value={values.ownerUserName}
                        onChange={handleChange}
                        error={errors.ownerUserName && touched.ownerUserName}
                        helperText={
                          errors.ownerUserName && touched.ownerUserName && errors.ownerUserName
                        }
                      />
                    </Grid> 


                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="شماره ترمینال"
                        variant="outlined"
                        fullWidth
                        name="terminalCode"
                        type="number"
                        value={values.terminalCode}
                        onChange={handleChange}
                        error={errors.terminalCode && touched.terminalCode}
                        helperText={
                          errors.terminalCode && touched.terminalCode && errors.terminalCode
                        }
                      />
                    </Grid> 


                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="ایمیل"
                        variant="outlined"
                        fullWidth
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        error={errors.email && touched.email}
                        helperText={
                          errors.email && touched.email && errors.email
                        }
                      />
                    </Grid> 


<Grid item xs={12} md={6} lg={4}>
  <TextField
    label="موبایل"
    variant="outlined"
    fullWidth
    name="mobile"
    type="number"
    value={values.mobile}
    onChange={handleChange}
    error={errors.mobile && touched.mobile}
    helperText={
      errors.mobile && touched.mobile && errors.mobile
    }
  />
</Grid> 



                    <Grid item xs={12} md={12} lg={4}>
                      <TextField
                        label="description"
                        variant="outlined"
                        fullWidth
                        name="description"
                        type="string"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description && touched.description}
                        helperText={
                          errors.description && touched.description && errors.description
                        }
                      />
                    </Grid> 

   
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isActive" 
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فعال"
                      />
                    </Grid>
   
                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      
                    </Grid>



                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={onNavigateToListPage}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TerminalModifyPage;
