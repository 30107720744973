import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";

 
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import DefaultApiService from "../../../../../infrastructure/api/default";
 
import TerminalSelect from "../../../../components/pay/TerminalSelect";

 


const validationSchema = Yup.object().shape({

 
  title: Yup.string().required("لطفا این فیلد را پر کنید."),    
  payerId: Yup.number().required("لطفا این فیلد را پر کنید."),  
  mobile: Yup.number().required("لطفا این فیلد را پر کنید."),    
  isActive:  Yup.bool(),
  description: Yup.string().required("لطفا این فیلد را پر کنید."), 
  terminalId:  Yup.number().required("لطفا این فیلد را پر کنید."), 
 
});

const PayerModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    navigate("/YSAPAY/Management/Payer");
  };
  
 
    
  

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try { 

       
 
      const res = await DefaultApiService.modifyApi('/YSAPAY/Management/Payer', 
        sendData
      );

      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create" ? `ویرایش ${data?.payer?.title}` : "ایجاد"
            }
          />

          <CardContent>
            <Formik
              initialValues={data.payer}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>


                    
                  <Grid item xs={12} md={6} lg={4}>
                      <TerminalSelect
                        label="شناسه درگاه"
                        value={values.terminalId}
                        onChange={(event) => {
                          setFieldValue("terminalId", event.target.value);
                        }}
                        terminalList={data.terminalList}
                        error={errors.terminalId && touched.terminalId}
                        helperText={
                          errors.terminalId &&
                          touched.terminalId &&
                          errors.terminalId
                        }
                      />
                    </Grid>



                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="عنوان"
                        variant="outlined"
                        fullWidth
                        name="title"
                        type="string"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title && touched.title}
                        helperText={
                          errors.title && touched.title && errors.title
                        }
                      />
                    </Grid>

 
<Grid item xs={12} md={12} lg={4}>
  <TextField
    label="شناسه پرداخت"
    variant="outlined"
    fullWidth
    name="payerId"
    type="string"
    value={values.payerId}
    onChange={handleChange}
    error={errors.payerId && touched.payerId}
    helperText={
      errors.payerId && touched.payerId && errors.payerId
    }
  />
</Grid> 
          
 
 

 
<Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="موبایل"
                        variant="outlined"
                        fullWidth
                        name="mobile"
                        type="string"
                        value={values.mobile}
                        onChange={handleChange}
                        error={errors.mobile && touched.mobile}
                        helperText={
                          errors.mobile && touched.mobile && errors.mobile
                        }
                      />
                    </Grid>
                    
 


                    <Grid item xs={12} md={12} lg={4}>
                      <TextField
                        label="description"
                        variant="outlined"
                        fullWidth
                        name="description"
                        type="string"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description && touched.description}
                        helperText={
                          errors.description && touched.description && errors.description
                        }
                      />
                    </Grid> 

 
                    
                    

       
   
 
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isActive"
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فعال"
                      />
                    </Grid>






  
                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      
                    </Grid>





                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={onNavigateToListPage}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PayerModifyPage;
