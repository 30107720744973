import React, { useMemo, useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { guid } from "../../../middleware/middleware";

const containsText = (text, searchText) =>
  text.toString().toLowerCase().includes(searchText.toLowerCase());

const DropdownPicker = ({
  value,
  label,
  list,
  id = guid(),
  optionName = "name",
  optionValue = "value",
  error,
  helperText,
  filterable = true,
  size = "medium",
  ...params
}) => {
  const [searchText, setSearchText] = useState("");
  const displayedOptions = useMemo(() => {
    return list.filter((option) =>
      containsText(option[optionName], searchText)
    );
  }, [list, optionName, searchText]);

  return (
    <FormControl fullWidth size={size}>
      <InputLabel id={id}>{label}</InputLabel>

      <Select
        value={value}
        labelId={id}
        label={label}
        error={error}
        id={`select-box-${id}`}
        {...params}
        onClose={() => setSearchText("")}
      >
        {filterable ? (
          <ListSubheader>
            <TextField
              // Autofocus on textfield
              autoFocus
              placeholder="جستجو..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              value={searchText}
              size="small"
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        ) : null}
        {displayedOptions.map((x) => (
          <MenuItem key={x[optionValue]} value={x[optionValue]}>
            {x[optionName]}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default DropdownPicker;
