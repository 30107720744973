import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import { toast } from "react-toastify";
import { useState } from "react";
import DefaultApiService from "../../../infrastructure/api/default";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("لطفا این فیلد را پر کنید."),
  newPassword: Yup.string().required("لطفا این فیلد را پر کنید."),
  confirmNewPassword: Yup.string()
    .required("لطفا این فیلد را پر کنید.")
    .oneOf([Yup.ref("newPassword"), null], "با کلمه عبور جدید همخوانی ندارد."),
});

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onNavigateToDashboard = () => {
    navigate("/");
  };

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);

    try {
      const res = await DefaultApiService.postApi(
        "Account/ResetPassword",
        formValue
      );

      if (res.isSuccess) {
        onNavigateToDashboard();
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title={"تغییر کلمه عبور"} />
          <CardContent>
            <Formik
              initialValues={{
                password: "",
                newPassword: "",
                confirmNewPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                const onMakeFormikVariable = (name) => ({
                  name: name,
                  fullWidth: true,
                  value: values[name],
                  error: errors[name] && touched[name],
                  helperText: errors[name] && touched[name] && errors[name],
                  variant: "outlined",
                });

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="کلمه عبور فعلی"
                          onChange={handleChange}
                          type="password"
                          {...onMakeFormikVariable("password")}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="کلمه عبور جدید"
                          onChange={handleChange}
                          type="password"
                          {...onMakeFormikVariable("newPassword")}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} lg={4}>
                        <TextField
                          label="تایید کلمه عبور جدید"
                          onChange={handleChange}
                          type="password"
                          {...onMakeFormikVariable("confirmNewPassword")}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={12}
                        container
                        justifyContent={"flex-end"}
                      >
                        <Button
                          disabled={isSubmitting}
                          variant="outlined"
                          size="large"
                          color="gray"
                          sx={{ mx: 1 }}
                          onClick={onNavigateToDashboard}
                        >
                          انصراف
                        </Button>

                        <Button
                          disabled={isSubmitting}
                          variant="contained"
                          type="submit"
                          size="large"
                        >
                          {loading && (
                            <CircularProgress size={16} color="inherit" />
                          )}
                          ذخیره
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ResetPasswordPage;
