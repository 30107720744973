import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getUserMenuList } from "../../../application/selectors/user";

const useCheckPermission = (mode) => {
  const { pathname } = useLocation();
  const menuList = useSelector(getUserMenuList);

  const [hasPermission, setHasPermission] = useState(true);

  useEffect(() => {
    const pathnames = pathname.split("/").filter((x) => x);
    if (menuList && menuList.length > 0) {
      const specificNode = onFindSpecificNode(pathnames, {
        child: menuList,
      });

      let accessed = false;
      if (specificNode?.actionAccess?.[mode]) {
        accessed = specificNode.actionAccess[mode];
      }

      if (specificNode.actionAccess === null && specificNode?.level === 1) {
        accessed = true;
      }
      // console.log("CheckPermission SpecificNode", mode, accessed);
      setHasPermission(accessed);
    }
    // else {
    //   setHasPermission(false);
    // }
  }, [menuList, mode, pathname]);

  const onFindSpecificNode = (pathnames, menuList) => {
    let specificNode = menuList;

    pathnames.forEach((path) => {
      let item = null;
      if (specificNode.level === 1) {
        let child = [];
        for (let i = 0; i < specificNode.child.length; i++) {
          child = [...child, ...specificNode.child[i].child];
        }
        item = child.find(
          (menu) => menu.oldLink.toLowerCase() === path.toLowerCase()
        );
      } else {
        if (Array.isArray(specificNode?.child)) {
          item = specificNode.child.find(
            (menu) => menu.oldLink.toLowerCase() === path.toLowerCase()
          );
        }
      }

      if (item) {
        specificNode = item;
      }
    });

    return specificNode;
  };

  return hasPermission;
};

export default useCheckPermission;
