import * as React from "react";
import TextField from "@mui/material/TextField";
import AdapterJalali from "@date-io/date-fns-jalali";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toMiladyDate } from "../../../middleware/middleware";

const JalaliDatePicker = ({
  value,
  label,
  onChange,
  size = "medium",
  ...other
}) => {
  // const [value, setValue] = React.useState(new Date(2022, 3, 7));

  return (
    <LocalizationProvider dateAdapter={AdapterJalali}>
      <DatePicker
        mask="____/__/__"
        value={value}
        label={label}
        onChange={(newValue) => {
          const date = toMiladyDate(newValue);
          onChange(date);
        }}
        renderInput={(params) => (
          <TextField {...other} {...params} size={size} />
        )}
      />
    </LocalizationProvider>
  );
};

export default JalaliDatePicker;
