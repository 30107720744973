import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import ModuleDashboard from "../../components/dashboard/main";
import BreadcrumbsComponent from "../../components/shared/breadcrumbs";
import { getUserMenuList } from "../../../application/selectors/user";

const UserManagementPage = () => {
  const [menu, setMenu] = useState({ title: "مدیریت کاربران", children: [] });
  const menuList = useSelector(getUserMenuList);

  useEffect(() => {
    if (menuList && Array.isArray(menuList)) {
      const item = menuList.find((x) => x.link === "/UM");
      if (item) {
        setMenu(item);
      }
    }
  }, [menuList]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <ModuleDashboard menuList={menu.child} cartTitle={menu.title} />
      </Grid>
    </Grid>
  );
};




export default UserManagementPage;
