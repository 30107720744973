import { api, authHeader } from "../configs/axiosConfigs";

// Category
const getCategoryListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/Category/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getCategoryByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/Category/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const modifyCategoryApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Category/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteCategoryApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Category/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 




// Content
const getContentListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/Content/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getContentByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/Content/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyContentApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Content/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteContentApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Content/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 




// GlobalContent
const getGlobalContentListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/GlobalContent/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getGlobalContentByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/GlobalContent/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyGlobalContentApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/GlobalContent/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteGlobalContentApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/GlobalContent/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 




// ProductCategory
const getProductCategoryListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/ProductCategory/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getProductCategoryByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/ProductCategory/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const modifyProductCategoryApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/ProductCategory/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteProductCategoryApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/ProductCategory/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 




// Product
const getProductListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/Product/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getProductByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/Product/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyProductApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Product/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteProductApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Product/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 





// Customer
const getCustomerListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/Customer/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getCustomerByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/Customer/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyCustomerApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Customer/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteCustomerApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/Customer/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 





// License
const getLicenseListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/License/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getLicenseByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/License/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyLicenseApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/License/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteLicenseApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/License/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 







// SocialMedia
const getSocialMediaListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/SocialMedia/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getSocialMediaByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/SocialMedia/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifySocialMediaApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/SocialMedia/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteSocialMediaApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/SocialMedia/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 








// FooterLink
const getFooterLinkListApi = async () => {
  const response = await api.request({
    url: `CMS/Management/FooterLink/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getFooterLinkByIdApi = async (id) => {
  const response = await api.request({
    url: `CMS/Management/FooterLink/GetById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });
console.log(response.data)
  return response.data;
};

const modifyFooterLinkApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/FooterLink/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteFooterLinkApi = async (data) => {
  const response = await api.request({
    url: `CMS/Management/FooterLink/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};
 




const CMSService = {
  getProductCategoryListApi,
  getCategoryByIdApi,
  modifyCategoryApi,
  deleteCategoryApi,

  
  getContentListApi,
  getContentByIdApi,
  modifyContentApi,
  deleteContentApi,


  
  getGlobalContentListApi,
  getGlobalContentByIdApi,
  modifyGlobalContentApi,
  deleteGlobalContentApi,



  
  getProductCategoryListApi,
  getProductCategoryByIdApi,
  modifyProductCategoryApi,
  deleteProductCategoryApi,

  
  getProductListApi,
  getProductByIdApi,
  modifyProductApi,
  deleteProductApi,
  
  getCustomerListApi,
  getCustomerByIdApi,
  modifyCustomerApi,
  deleteCustomerApi,

  
  getSocialMediaListApi,
  getSocialMediaByIdApi,
  modifySocialMediaApi,
  deleteSocialMediaApi,


  
  getFooterLinkListApi,
  getFooterLinkByIdApi,
  modifyFooterLinkApi,
  deleteFooterLinkApi,


  
  getLicenseListApi,
  getLicenseByIdApi,
  modifyLicenseApi,
  deleteLicenseApi
 
};

export default CMSService;
