import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../../infrastructure/api/user";
import { setLoading } from "./ui";

const initialState = {
  menu: [],
  selectedMenu: null,
};

const onMakeMenuLink = (parent, child) => {
  // console.group("onMakeMenuLink");
  // console.log("parent", parent);
  // console.log("child", child);
  // console.groupEnd();

  child.forEach((menu) => {
    menu.oldLink = menu.link;
    if (menu.level === 0) {
      menu.link = "/" + menu.link;
    } else if (menu.level === 2) {
      menu.link = parent.link;
    } else {
      menu.link = parent.link + "/" + menu.link;
    }

    if (Array.isArray(menu.child)) {
      menu = onMakeMenuLink(menu, menu.child);
    }
  });
  return child;
};

export const setUserMenuList = createAsyncThunk(
  "user/menu",
  async (arg, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const res = await userService.getMenuData();

      const menulist = onMakeMenuLink({}, res.data);

      dispatch(setLoading(false));
      return { menu: menulist };
    } catch (error) {
      dispatch(setLoading(false));
      return rejectWithValue();
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setSelectedMenu: (state, action) => {
      return { selectedMenu: action.payload };
    },
  },
  extraReducers: {
    [setUserMenuList.fulfilled]: (state, action) => {
      state.menu = action.payload.menu;
    },
    [setUserMenuList.rejected]: (state, action) => {
      state.menu = [];
    },
  },
});

const { reducer, actions } = userSlice;

export const { setSelectedMenu } = actions;
export default reducer;
