import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import KeyIcon from "@mui/icons-material/Key";
import BoltIcon from "@mui/icons-material/Bolt";
import SettingsIcon from "@mui/icons-material/Settings";
import BarChartIcon from "@mui/icons-material/BarChart";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import InsightsIcon from "@mui/icons-material/Insights";

const iconTypes = {
  Home: HomeIcon,
  MNG: KeyIcon,
  BI: SettingsIcon,
  ES: BoltIcon,
  ESB: BarChartIcon,
  FC: TrendingUpIcon,
  DG: OfflineBoltIcon,
  EM: QueryStatsIcon,
  UM: ManageAccountsIcon,
  CM: NewspaperIcon,
  GV: InsightsIcon,
};

const MuiIcon = ({ name, ...props }) => {
  let Icon = iconTypes[name] || iconTypes["Home"];

  return <Icon {...props} />;
};

export default MuiIcon;
