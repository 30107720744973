import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SearchIcon from "@mui/icons-material/Search";
import { toast } from "react-toastify";

import { toMiladyFullDate } from "../../../../middleware/middleware";
import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import JalaliDatePicker from "../../../../components/shared/jalali-datepicker";
import DefaultApiService from "../../../../../infrastructure/api/default";
import ReportDataShow from "../../../../components/shared/report/ReportDataShow";
import useGetPageInfo from "../../../../common/hooks/useGetPageInfo";
import { LoadingButton } from "@mui/lab";
import { useLoaderData } from "react-router-dom";
import DropdownPicker from "../../../../components/shared/dropdown";

const initialValues = {
  TerminalId: "0",
  StartDate: toMiladyFullDate(new Date()),
  EndDate: toMiladyFullDate(new Date()),
};

const GatwayTransactionPage = () => {
  const dataList = useLoaderData("root");
  const pageInfo = useGetPageInfo();
  const [tableData, setTableData] = useState({
    columns: [],
    data: [],
  });
  const [chartData, setChartData] = useState({
    data: [],
  });

  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState(initialValues);

  const onClearFilterValues = () => {
    setFilterValues(() => initialValues);
  };

  const onGetReportData = async () => {
    try {
      setLoading(true);

      const res = await DefaultApiService.postApi(
        "/YSAPAY/Management/GatwayTransaction/GetData",
        filterValues
      );

      setLoading(false);
      // Make Table Data
      if (res?.data?.tableData) {
        const table = res?.data?.tableData;

        const tableColumns = table.columns.map((x) => ({
          id: x,
          label: x,
        }));
        const tableData = table.data.map((row, index) => {
          let item = {};

          for (let i = 0; i < row.length; i++) {
            item[table.columns[i]] = row[i];
          }
          return item;
        });

        setTableData((old) => ({
          ...old,
          columns: tableColumns,
          data: tableData,
        }));
      }

      // Make Chart Data
      if (res?.data?.chartData) {
        const chart = res?.data?.chartData;
        let finalChartData = [];

        finalChartData = chart.xValues.map((item) => ({ x: item }));

        for (let i = 0; i < chart.data.length; i++) {
          const yData = chart.data[i];
          const sereTitle = chart.seriesTitle[i];

          for (let j = 0; j < yData.length; j++) {
            finalChartData[j][sereTitle] = yData[j];
          }
        }

        // console.log("finalChartData", finalChartData);

        setChartData({
          ...chart,
          data: finalChartData,
        });
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={pageInfo?.title}
            action={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <LoadingButton
                  variant="outlined"
                  color="warning"
                  startIcon={<SearchIcon />}
                  onClick={onGetReportData}
                  loading={loading}
                >
                  جستجو
                </LoadingButton>
                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1 }}
                />
                <Button
                  variant="text"
                  color="gray"
                  startIcon={<HighlightOffIcon />}
                  onClick={onClearFilterValues}
                >
                  حذف فیلترها
                </Button>
              </Box>
            }
          />
          <CardContent>
            <Grid container>
              {/* Filter Section */}
              <Grid container item xs={12} md={12} className="filter-bar">
                <Grid item container xs={12} md={12} spacing={2}>
                  <Grid item xs={12} md={4} lg={2}>
                    <DropdownPicker
                      label="ترمینال"
                      value={filterValues.TerminalId}
                      onChange={(event) => {
                        setFilterValues((old) => ({
                          ...old,
                          TerminalId: event.target.value,
                        }));
                      }}
                      list={
                        dataList.terminalList
                          ? [{ terminalCode: "0", title: "همه" }, ...dataList.terminalList]
                          : []
                      }
                      optionName="title"
                      optionValue="terminalCode"
                      size="small"
                    />
                  </Grid>

                  
                  <Grid item xs={12} md={4} lg={2}>
                    <DropdownPicker
                      label="نوع تراکنش"
                      value={filterValues.RequestStatus}
                      onChange={(event) => {
                        setFilterValues((old) => ({
                          ...old,
                          RequestStatus: event.target.value,
                        }));
                      }}
                      list={dataList.requestStatus}
                      size="small"
                    />
                  </Grid>


                  <Grid item xs={12} md={4} lg={2}>
                    <JalaliDatePicker
                      label="از تاریخ"
                      fullWidth
                      value={filterValues.StartDate}
                      onChange={(value) => {
                        setFilterValues((old) => ({
                          ...old,
                          StartDate: value,
                        }));
                      }}
                      size="small"
                    />
                  </Grid>

                  <Grid item xs={12} md={4} lg={2}>
                    <JalaliDatePicker
                      label="تا تاریخ"
                      fullWidth
                      value={filterValues.EndDate}
                      onChange={(value) => {
                        setFilterValues((old) => ({
                          ...old,
                          EndDate: value,
                        }));
                      }}
                      size="small"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* Data */}
              <ReportDataShow
                tableData={tableData}
                chartData={chartData}
                loading={loading}
              />
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default GatwayTransactionPage;
