import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import Dashboard from "../components/dashboard/main";
import { getUserMenuList } from "../../application/selectors/user";
import { useLoaderData } from "react-router-dom";
import DefaultApiService from "../../infrastructure/api/default";
import BreadcrumbsComponent from "../components/shared/breadcrumbs";

const Home = () => {
  const menuList = useSelector(getUserMenuList);
  const [loading, setLoading] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    try {
      setLoading(true);
      DefaultApiService.getApi("Home/GetData", {}, true).then((res) => {
        if (res?.data?.dataList) {
          setDataList(res?.data?.dataList);
          setLoading(false);
        }
      });
    } catch (error) {
      setLoading(false);
    }
  }, []);

  return (
    <Grid container className="home-container" spacing={3}>
      <Grid item xs={12} md={12}>
        <Dashboard
          menuList={menuList}
          cartTitle="داشبورد"
          blockList={dataList}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
};

export default Home;
