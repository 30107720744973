import React from "react";
import { ReactComponent as UserIcon } from "../../../assets/icons/user.svg";
import { ReactComponent as FlashIcon } from "../../../assets/icons/flash.svg";
import { ReactComponent as ShareIcon } from "../../../assets/icons/share.svg";
import { ReactComponent as HomeTrendUpIcon } from "../../../assets/icons/home-trend-up.svg";
import { ReactComponent as SettingIcon } from "../../../assets/icons/setting.svg";
import { ReactComponent as ChartIcon } from "../../../assets/icons/chart.svg";
import { ReactComponent as DiagramIcon } from "../../../assets/icons/diagram.svg";
import { ReactComponent as DiagramFlashIcon } from "../../../assets/icons/diagram-flash.svg";
import { ReactComponent as KeyIcon } from "../../../assets/icons/key.svg";
import { ReactComponent as PresentationChartIcon } from "../../../assets/icons/presentation-chart.svg";
import { ReactComponent as StatusUpIcon } from "../../../assets/icons/status-up.svg";
import { ReactComponent as UserEditIcon } from "../../../assets/icons/user-edit.svg";
import { ReactComponent as GridIcon } from "../../../assets/icons/grid.svg";

const iconTypes = {
  user: UserIcon,
  ES: FlashIcon,
  DG: ShareIcon,
  FC: HomeTrendUpIcon,
  BI: SettingIcon,
  chart: ChartIcon,
  ESB: ChartIcon,
  diagram: DiagramIcon,
  EM: DiagramFlashIcon,
  MNG: KeyIcon,
  CM: PresentationChartIcon,
  GV: StatusUpIcon,
  UM: UserEditIcon,
  grid: GridIcon,
};

const IconComponent = ({ name, link, ...props }) => {
  let Icon = iconTypes[name] || iconTypes["user"];

  if (link && !iconTypes[name]) {
    for (const [key] of Object.entries(iconTypes)) {
      if (link.includes(key)) {
        Icon = iconTypes[key];
        break;
      }
    }
  }

  return <Icon {...props} />;
};

export default IconComponent;
