import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet, useLocation, useNavigation } from "react-router-dom";

import {
  getIsLoading,
  getIsShowModuleSidebar,
} from "../../../application/selectors/ui";
import AppBarContainer from "./AppBar";
import SideBarContainer from "./SideBar";
import { LinearProgress } from "@mui/material";
import ModuleSideBar from "./ModuleSideBar";

export default function Layout() {
  const { pathname } = useLocation();
  const { state } = useNavigation();

  const isShowModuleSideBar = useSelector(getIsShowModuleSidebar);
  const isLoading = useSelector(getIsLoading);

  const [open, setOpen] = useState(false);
  const main = useRef();

  useEffect(() => {
    if (main) {
      window.scrollTo(0, 0);
      main.current.scrollTo(0, 0);
    }
  }, [pathname]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }} className="app-layout-container">
      <CssBaseline />

      <AppBarContainer
        open={open}
        handleDrawerClose={handleDrawerClose}
        handleDrawerOpen={handleDrawerOpen}
      />

      <Box
        component="main"
        className={clsx({
          "app-content-container": true,
          "full-width": !isShowModuleSideBar,
        })}
        ref={main}
      >
        {(isLoading || state === "loading") && (
          <Box sx={{ width: "100%", position: "absolute", top: 0, left: 0 }}>
            <LinearProgress />
          </Box>
        )}

        <SideBarContainer open={open} setOpen={setOpen} />
        <ModuleSideBar />

        <Outlet />
      </Box>
    </Box>
  );
}
