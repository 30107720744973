import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import { Link, useLoaderData, useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import EnhancedTable from "../../../../components/shared/table";
import DeleteConfirm from "../../../../components/shared/Dialog/DeleteConfirm";
import useCheckPermission from "../../../../common/hooks/useCheckPermission";
import SHOPService from "../../../../../infrastructure/api/shop";
import DefaultApiService from "../../../../../infrastructure/api/default";

const TerminalPage = () => {
  const brandInfoList = useLoaderData("root");
  let navigate = useNavigate();
  const [deleteConfirm, setDeleteConfirm] = useState({
    open: false,
    data: {},
    cascadeDelete: false,
  });
  const headCells = [
    {
      id: "updateOn",
      label: "تاریخ بروزرسانی",
      type: "date",
    },
    {
      id: "title", 
      label: "عنوان",
    }, 
    {
      id: "terminalCode",
      disablePadding: false,
      label: "شماره ترمینال",
    }, {
      id: "ownerUserName",
      disablePadding: false,
      label: "نام کاربری مدیر",
    }, {
      id: "email",
      disablePadding: false,
      label: "ایمیل",
    }, {
      id: "mobile",
      disablePadding: false,
      label: "موبایل",
    },  
    {
      id: "isActive",
      type: "boolean",
      disablePadding: false,
      label: "وضعیت",
    },
  ];

  const onEditClicked = (row) => {
    navigate(`${row.id}`);
  };

  const onDeleteClicked = (row) => {
    setDeleteConfirm((old) => ({ ...old, open: true, data: row }));
  };

  const confirmedDelete = () => {
    return DefaultApiService.deleteOneApi('/YSAPAY/Management/Terminal',{
      id: deleteConfirm.data.id,
      cascadeDelete: deleteConfirm.cascadeDelete,
    });
  };

  const onFetchData = () => {
    navigate();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />

        <DeleteConfirm
          message={`برای حذف "${deleteConfirm.data.title}" اطمینان دارید؟`}
          open={deleteConfirm.open}
          onClose={(loadAgain) => {
            setDeleteConfirm({
              open: false,
              data: {},
              cascadeDelete: false,
            });
            if (loadAgain) {
              navigate();
            }
          }}
          onSubmit={confirmedDelete}
        >
          <FormControlLabel
            name="cascadeDelete"
            checked={deleteConfirm.cascadeDelete}
            onChange={(event) => {
              setDeleteConfirm((old) => ({
                ...old,
                cascadeDelete: event.target.checked,
              }));
            }}
            control={<Switch color="danger" />}
            label="حذف از تمامی جاهایی که استفاده شده است"
          />
        </DeleteConfirm>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title="دسته بندی"
            action={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                {useCheckPermission("create") && (
                  <Link to={"create"}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                    >
                      ایجاد
                    </Button>
                  </Link>
                )}

                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1 }}
                />

                <IconButton
                  variant="text"
                  color="primary"
                  onClick={onFetchData}
                >
                  <SyncIcon />
                </IconButton>
              </Box>
            }
          />

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <EnhancedTable
                  rowKey={"id"}
                  rows={brandInfoList}
                  headCells={headCells}
                  pageSize={10}
                  actions={[
                    {
                      icon: useCheckPermission("edit") ? (
                        <EditIcon sx={{ width: "1rem", height: "1rem" }} />
                      ) : null,
                      onClick: onEditClicked,
                      color: "primary",
                    },
                    {
                      icon: useCheckPermission("delete") ? (
                        <DeleteIcon sx={{ width: "1rem", height: "1rem" }} />
                      ) : null,
                      onClick: onDeleteClicked,
                      color: "secondary",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default TerminalPage;
