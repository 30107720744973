import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import { Link, useLoaderData, useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import PreviewIcon from '@mui/icons-material/Preview';
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import EnhancedTable from "../../../../components/shared/table";
// import ImagePreview from "../../../../components/test/ImagePreview";
import DeleteConfirm from "../../../../components/shared/Dialog/DeleteConfirm";
import useCheckPermission from "../../../../common/hooks/useCheckPermission";
import CMSService from "../../../../../infrastructure/api/cms";
import ImagePreview from "../../../../components/test/ImagePreview";

const CMSGlobalContentPage = () => {
  const categoryList = useLoaderData("root");
  let navigate = useNavigate();
  const [deleteConfirm, setDeleteConfirm] = useState({
    open: false,
    data: {},
    cascadeDelete: false,
  });

  const [imagePreview, setPreviewConfirm] = useState({
    open: false,
    data: {}, 
  });





  const headCells = [
    {
      id: "updateOn",
      label: "تاریخ بروزرسانی",
      type: "date",
    },
    {
      id: "id",
      label: "", 
    }
     
  ];

  const onEditClicked = (row) => {
    navigate(`${row.id}`);
  };
 
  

  const onFetchData = () => {
    navigate();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
   

    
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
           

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <EnhancedTable 
                  showFilter={false}
                  rows={categoryList}
                  headCells={headCells} 
                  actions={[
                      {
                      icon: useCheckPermission("edit") ? (
                        <EditIcon sx={{ width: "1rem", height: "1rem" }} />
                      ) : null,
                      onClick: onEditClicked,
                      color: "primary",
                    } 
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CMSGlobalContentPage;
