import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getAuthSlice } from "../../../application/selectors/auth";

const PrivateRoute = ({ children }) => {
  let location = useLocation();
  const { isLoggedIn } = useSelector(getAuthSlice);
  const user = JSON.parse(localStorage.getItem("user"));

  if (!isLoggedIn || !user?.access_token) {
    localStorage.removeItem("user");

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};

export default PrivateRoute;
