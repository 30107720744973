import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik"; 
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs"; 
import JalaliDateTimePicker from "../../../../components/shared/jalali-datetimepicker";
import DropdownPicker from "../../../../components/shared/dropdown";
 
import SHOPService from "../../../../../infrastructure/api/shop"; 

const validationSchema = Yup.object().shape({   

  title: Yup.string().required("لطفا این فیلد را پر کنید."),   
  isActive:  Yup.bool(),
  description: Yup.string().required("لطفا این فیلد را پر کنید."),  
 
});

const DiscountDefinitionModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    navigate("/SHOP/Management/DiscountDefinition");
  };
   

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try { 
       console.log(data)
      const res = await SHOPService.modifyDiscountApi(
        sendData
      );

      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create" ? `ویرایش ${data?.discount?.title}` : "ایجاد"
            }
          />

<CardContent>
            <Formik
              initialValues={data.discount}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                const onMakeFormikVariable = (name) => ({
                  name: name,
                  fullWidth: true,
                  value: values[name],
                  error: errors[name] && touched[name],
                  helperText: errors[name] && touched[name] && errors[name],
                  variant: "outlined",
                });

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
 
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="عنوان"
                        variant="outlined"
                        fullWidth
                        name="title"
                        type="string"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title && touched.title}
                        helperText={
                          errors.title && touched.title && errors.title
                        }
                      />
                    </Grid> 


                    
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="کد تخفیف"
                        variant="outlined"
                        fullWidth
                        name="discountCode"
                        type="string"
                        value={values.discountCode}
                        onChange={handleChange}
                        error={errors.discountCode && touched.discountCode}
                        helperText={
                          errors.discountCode && touched.discountCode && errors.discountCode
                        }
                      />
                    </Grid> 



                    
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="درصد"
                        variant="outlined"
                        fullWidth
                        name="percent"
                        type="string"
                        value={values.percent}
                        onChange={handleChange}
                        error={errors.percent && touched.percent}
                        helperText={
                          errors.percent && touched.percent && errors.percent
                        }
                      />
                    </Grid> 


                    
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="تعداد"
                        variant="outlined"
                        fullWidth
                        name="maxNumber"
                        type="string"
                        value={values.maxNumber}
                        onChange={handleChange}
                        error={errors.maxNumber && touched.maxNumber}
                        helperText={
                          errors.maxNumber && touched.maxNumber && errors.maxNumber
                        }
                      />
                    </Grid> 


{/*                     
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="تاریخ شروع"
                        variant="outlined"
                        fullWidth
                        name="startDateTime"
                        type="date"
                        value={values.startDateTime}
                        onChange={handleChange}
                        error={errors.startDateTime && touched.startDateTime}
                        helperText={
                          errors.startDateTime && touched.startDateTime && errors.startDateTime
                        }
                      />
                    </Grid>  */}


<Grid item xs={12} md={6} lg={4}>
                        <JalaliDateTimePicker
                          label="تاریخ شروع"
                          onChange={(value) => {
                            setFieldValue("startDateTime", value);
                          }}
                          {...onMakeFormikVariable("startDateTime")}
                        />
                      </Grid>

<Grid item xs={12} md={6} lg={4}>
                        <JalaliDateTimePicker
                          label="تاریخ پایان"
                          onChange={(value) => {
                            setFieldValue("endDateTime", value);
                          }}
                          {...onMakeFormikVariable("endDateTime")}
                        />
                      </Grid>

{/*                     
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="تاریخ پایان"
                        variant="outlined"
                        fullWidth
                        name="endDateTime"
                        type="date"
                        value={values.endDateTime}
                        onChange={handleChange}
                        error={errors.endDateTime && touched.endDateTime}
                        helperText={
                          errors.endDateTime && touched.endDateTime && errors.endDateTime
                        }
                      />
                    </Grid>  */}

                    
{/* 
                    
                    <Grid item xs={12} md={12} lg={4}>
                      <TextField
                        label="نوع تخفیف"
                        variant="outlined"
                        fullWidth
                        name="discountTypes"
                        type="string"
                        value={values.discountTypes}
                        onChange={handleChange}
                        error={errors.discountTypes && touched.discountTypes}
                        helperText={
                          errors.discountTypes && touched.discountTypes && errors.discountTypes
                        }
                      />
                    </Grid>  */}


                    <Grid item xs={12} md={6} lg={4}>
                        <DropdownPicker
                          label="نوع تخفیف"
                          onChange={(event) => {
                            setFieldValue("discountTypes", event.target.value);
                          }}
                          list={data.discountTypes}
                          {...onMakeFormikVariable("discountTypes")}
                        />
                      </Grid>



                    <Grid item xs={12} md={12} lg={4}>
                      <TextField
                        label="توضیحات"
                        variant="outlined"
                        fullWidth
                        name="description"
                        type="string"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description && touched.description}
                        helperText={
                          errors.description && touched.description && errors.description
                        }
                      />
                    </Grid> 

   
                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isActive" 
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فعال"
                      />
                    </Grid>
  
 
                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={onNavigateToListPage}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DiscountDefinitionModifyPage;
