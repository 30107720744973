import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  TextField,
  Button,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as UnlockIcon } from "../../assets/icons/unlock.svg";
import LogoIcon from "../../assets/images/logo-icon.png";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage } from "../../../application/slices/message";
import { getAuthSlice } from "../../../application/selectors/auth";
import { getMessageSlice } from "../../../application/selectors/message";
import { login } from "../../../application/slices/auth";

const LoginPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const [loading, setLoading] = useState(false);

  const { isLoggedIn } = useSelector(getAuthSlice);
  const { message } = useSelector(getMessageSlice);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  const initialValues = {
    username: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("لطفا این فیلد را پر کنید."),
    password: Yup.string().required("لطفا این فیلد را پر کنید."),
    rememberMe: Yup.bool(),
  });

  const onSubmit = (formValue, { setSubmitting }) => {
    const { username, password } = formValue;

    setLoading(true);

    dispatch(login({ username, password }))
      .unwrap()
      .then(() => {
        navigate(from, { replace: true });
        // window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const user = JSON.parse(localStorage.getItem("user"));
  if (isLoggedIn && user?.access_token) {
    return <Navigate to="/" />;
  }

  return (
    <Card variant="outlined" className="auth-card-container">
      <CardContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item md={12} className="auth-header">
                  <img src={LogoIcon} alt="PMS" className="auth-logo" />
                  <div className="auth-title">ورود به حساب کاربری</div>
                </Grid>

                <Grid item md={12}>
                  <TextField
                    label="نام کاربری"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <UserIcon />
                        </InputAdornment>
                      ),
                    }}
                    name="username"
                    type="string"
                    value={values.username}
                    onChange={handleChange}
                    error={errors.username && touched.username}
                    helperText={
                      errors.username && touched.username && errors.username
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <TextField
                    label="رمز عبور"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <UnlockIcon />
                        </InputAdornment>
                      ),
                    }}
                    name="password"
                    type="password"
                    value={values.password}
                    onChange={handleChange}
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                  />
                </Grid>

                <Grid item md={12}>
                  <FormControlLabel
                    name="rememberMe"
                    value={values.rememberMe}
                    onChange={handleChange}
                    control={<Checkbox />}
                    label="مرا بخاطر بیاور"
                  />
                </Grid>

                <Grid item md={12}>
                  <Button
                    fullWidth
                    disabled={isSubmitting}
                    variant="contained"
                    type="submit"
                    size="large"
                  >
                    {loading && <CircularProgress size={16} color="inherit" />}
                    ورود
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>

        {message && (
          <Alert sx={{ mt: 3 }} severity="error">
            {message}
          </Alert>
        )}
      </CardContent>
    </Card>
  );
};

export default LoginPage;
