import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Slide,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import SupportIcon from "@mui/icons-material/Support";
import parse from "html-react-parser";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { getUserMenuList } from "../../../../application/selectors/user";

function LinkRouter(props) {
  return <Link {...props} component={RouterLink} />;
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BreadcrumbsComponent = () => {
  const location = useLocation();
  const menuList = useSelector(getUserMenuList);

  const [breadcrumbNameMap, setBreadcrumbNameMap] = useState({});
  const [userGuideModal, setUserGuideModal] = useState(false);
  const [menuItem, setMenuItem] = useState({});
  const pathnames = location.pathname.split("/").filter((x) => x);

  const handleOpen = () => setUserGuideModal(true);
  const handleClose = () => setUserGuideModal(false);

  const onMakeBreadcrumbData = (items) => {
    let data = {};
    items.forEach((menu) => {
      if (menu.level !== 2) {
        data[`${menu.link}`] = menu.title;
      }

      if (menu.link === location.pathname) {
        // console.log("menu", menu);
        setMenuItem(menu);
      }
      if (Array.isArray(menu.child)) {
        data = { ...data, ...onMakeBreadcrumbData(menu.child) };
      }
    });
    return data;
  };

  useEffect(() => {
    if (Array.isArray(menuList) && menuList.length > 0) {
      const breadcrumbs = onMakeBreadcrumbData(menuList);
      setBreadcrumbNameMap(breadcrumbs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menuList]);

  return (
    <Grid container>
      <Grid item xs={12} md={10}>
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<NavigateBeforeIcon fontSize="small" />}
        >
          <LinkRouter underline="hover" color="inherit" to="/">
            داشبورد
          </LinkRouter>
          {pathnames.map((value, index) => {
            const last = index === pathnames.length - 1;
            const to = `/${pathnames.slice(0, index + 1).join("/")}`;

            return last ? (
              <Typography color="text.primary" key={to}>
                {breadcrumbNameMap[to] || "ایجاد یا ویرایش"}
              </Typography>
            ) : (
              <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                {breadcrumbNameMap[to]}
              </LinkRouter>
            );
          })}
        </Breadcrumbs>
      </Grid>

      {menuItem.guid ? (
        <Grid
          item
          container
          xs={12}
          md={2}
          alignItems="center"
          justifyContent="end"
        >
          <Button onClick={handleOpen} color="info" startIcon={<SupportIcon />}>
            راهنمای کاربر
          </Button>

          <Dialog
            open={userGuideModal}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="user-guide-dialog"
            maxWidth={"md"}
          >
            <DialogTitle>راهنمای کاربر</DialogTitle>
            <DialogContent>
              <DialogContentText>{parse(menuItem.guid)}</DialogContentText>
            </DialogContent>
          </Dialog>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default BreadcrumbsComponent;
