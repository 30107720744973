import React from "react";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";

import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import SyncIcon from "@mui/icons-material/Sync";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import EnhancedTable from "../../../../components/shared/table";
import useCheckPermission from "../../../../common/hooks/useCheckPermission";
import useGetPageInfo from "../../../../common/hooks/useGetPageInfo";

const InProcessRequestPage = () => {
  const pageInfo = useGetPageInfo();
  const dataList = useLoaderData("root");

  let navigate = useNavigate();

  const headCells = [
    {
      id: "updateOn",
      label: "تاریخ بروزرسانی",
      type: "date",
    },
    {
      id: "id",
      disablePadding: false,
      label: "شماره فاکتور",
    }, {
      id: "price",
      disablePadding: false,
      label: "قیمت",
    },
    {
      id: "factorStatus",
      disablePadding: false,
      label: "وضعیت فاکتور",
    },
    {
      id: "paymentKind",
      disablePadding: false,
      label: "نوع پرداخت",
    },
    {
      id: "gatewayKind",
      disablePadding: false,
      label: "نوع درگاه" 
    },
    {
      id: "deliveryCost",
      disablePadding: false,
      label: "هزینه ارسال",
    },
    {
      id: "description",
      disablePadding: false,
      label: "توضیحات",
    }
        
  ];
  const onEditClicked = (row) => {
    navigate(`${row.id}`);
  };

  const onFetchData = () => {
    navigate();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={pageInfo.title}
            action={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <IconButton
                  variant="text"
                  color="primary"
                  onClick={onFetchData}
                >
                  <SyncIcon />
                </IconButton>
              </Box>
            }
          />

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <EnhancedTable
                  rowKey={"id"}
                  rows={dataList}
                  headCells={headCells}
                  pageSize={10}
                  actions={[
                    {
                      icon: useCheckPermission("edit") ? (
                        <ContentPasteSearchIcon />
                      ) : null,
                      onClick: onEditClicked,
                      color: "primary",
                      tooltip: "بررسی",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default InProcessRequestPage;
