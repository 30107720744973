import { createTheme } from "@mui/material/styles";
import { faIR } from "@mui/material/locale";
import { treeItemClasses } from "@mui/lab";

const defaultTheme = createTheme();

const theme = createTheme(
  {
    direction: "rtl",
    palette: {
      type: "light",
      primary: {
        900: "#001f82",
        800: "#073196",
        700: "#163ba2",
        600: "#2245ae",
        500: "#294db8",
        400: "#4f67c2",
        300: "#7083cd",
        200: "#99a5db",
        100: "#c2c8ea",
        50: "#e7e9f6",
        main: "#163ba2",
      },
      secondary: {
        main: "#ed001e",
      },
      info: {
        900: "#4339c8",
        800: "#415ee9",
        700: "#3f71fc",
        600: "#3a85ff",
        500: "#3195ff",
        400: "#42a5ff",
        300: "#63b6ff",
        200: "#92cbff",
        100: "#bedfff",
        50: "#e4f2ff",
        main: "#3f71fc",
      },
      navy: {
        900: "#2c5d85",
        800: "#387ca8",
        700: "#3c8dbc",
        600: "#45a0cf",
        500: "#4caedd",
        400: "#58bae2",
        300: "#6cc6e6",
        200: "#8fd6ed",
        100: "#b9e6f4",
        50: "#e3f6fb",
        main: "#3C8DBC",
      },
      success: {
        900: "#005e21",
        800: "#007e35",
        700: "#008f40",
        600: "#00a14c",
        500: "#00b056",
        400: "#3cbc6f",
        300: "#65c888",
        200: "#95d7aa",
        100: "#bfe7cb",
        50: "#e4f5ea",
        main: "#3cbc6f",
      },
      gray: {
        main: "#808080",
      },
      danger: {
        main: "#FF6A6A",
      },
    },
    typography: {
      fontFamily: "dana FD",
      fontSize: 12,
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorSecondary: {
            color: "#555555",
            backgroundColor: "#F1F7FF",
            // minHeight: "6rem",
          },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            borderRadius: 20,
            padding: 16,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          root: {
            padding: 0,
            [defaultTheme.breakpoints.down("sm")]: {
              flexDirection: "column",
              alignItems: "flex-start",
            },
          },
          content: {
            fontWeight: 700,
            fontSize: "1rem",
            color: "#555555",
            borderLeft: "2px solid #3C8DBC",
            paddingLeft: "1rem",
            [defaultTheme.breakpoints.down("sm")]: {
              marginBottom: "1rem",
            },
          },
          title: {
            fontWeight: 700,
            fontSize: "1rem",
            color: "#555555",
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: "1rem 0",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 5,
          },
        },
      },
      // Dialog
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: 15,
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            backgroundColor: "#C0D9FF",
            color: "#163BA2",
            textAlign: "center",
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            minHeight: "8rem",
            minWidth: "min(100vw , 24rem)",
            paddingTop: "0.5rem !important",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            minHeight: "3rem",
          },
        },
      },
      MuiTreeItem: {
        styleOverrides: {
          root: {
            color: "#808080",
            [`& .${treeItemClasses.content}`]: {
              borderRadius: 6,
              padding: "0.25rem 0 ",
              marginTop: 1,
              marginBottom: 1,
            },
            [`& .${treeItemClasses.expanded}`]: {
              backgroundColor: "#F1F7FF",
              color: "#163BA2",
            },
            [`& .${treeItemClasses.selected}`]: {
              backgroundColor: "#F1F7FF",
              color: "#163BA2",
              fontWeight: "bold",
            },
          },
        },
      },
    },
  },
  faIR
);

export default theme;
