import React, { useState ,useEffect } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useLocation } from "react-router-dom";
import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import DefaultApiService from "../../../../../infrastructure/api/default";
import DropdownPicker from "../../../../components/shared/dropdown";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const validationSchema = Yup.object().shape({
  title: Yup.string().required("لطفا این فیلد را پر کنید."),
  isActive: Yup.bool(),
});

const ContentModifyPage = () => {
  
  const location = useLocation();
  const { parentIds } = location.state || {}; 

  console.log(parentIds);
 
   

 // Access the route parameter using useParams
 const { treeId: routeTreeId } = useParams();

 // Initialize treeId state with the routeTreeId
 const [treeId, setTreeId] = useState(routeTreeId);
 
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    console.log('0000000000000000000000'  , parentIds);
    navigate(`/JP/Management/Content/${parentIds[parentIds.length - 1]}`, { state: { parentIds } });
  };

  const [files, setFiles] = React.useState([]);
  const [images, setIamge] = React.useState([]);


  useEffect(() => {
    setTreeId(routeTreeId);
  }, [routeTreeId]);
 
  const updateFiles = async (incomingFiles, isImage) => {
    try {
      console.log(isImage , 'isimage');
      if (incomingFiles.length > 0) {
        const lastIndex = incomingFiles.length - 1;
        const lastFile = incomingFiles[lastIndex];

        if (lastFile.valid) {
          const result = await DefaultApiService.uploadFile(
            lastFile.file,
            "JP/Content"
          );
          incomingFiles[lastIndex] = { ...lastFile, ...result.data };

          if (result.isSuccess) {
            toast.success("فایل با موفقیت آپلود شد.");
          } else {
            incomingFiles[lastIndex].valid = false;
            incomingFiles[lastIndex].errors = [result.message];
            toast.error(result.message);
          }
        }
      }
      console.log("incomingFiles", incomingFiles);
      if (isImage) {
        setIamge(incomingFiles);
      } else {
        setFiles(incomingFiles);
      }
 
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");
    }
  };

  const onRemoveFile = (fileIndex, event) => {
    event.preventDefault();
    event.stopPropagation();

    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
  };

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try {
      


      if (files[0] != null) { 
        sendData.fileUrl = files[0].directory;
      } else {
     
        const fileElement = document.getElementById("filePreviewHidden");
        sendData.fileUrl = fileElement.value.substring(
          fileElement.value.indexOf("/Files/")
        );
         
      }


      
      
      if (images[0] != null) {
        sendData.imageUrl = images[0].directory;
       
      } else {
        const element = document.getElementById("imagePreviewHidden");
        sendData.imageUrl = element.value.substring(
          element.value.indexOf("/Files/")
        );
      }




      if (sendData.levelKind === "Content") {
        sendData.price = formValue.price || 0; // Set the price from the form
      } else {
        sendData.content = null;
      }
   

      
      sendData.treeId = treeId;
  
      const res = await DefaultApiService.modifyApi(
        "/JP/Management/Content",
        sendData
      );

      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  }; 

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create" ? `ویرایش ${data?.tree?.title}` : "ایجاد"
            }
          /> 
          <CardContent>
            <Formik
              initialValues={data.tree}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => {
                const onMakeFormikVariable = (name) => ({
                  name: name,
                  fullWidth: true,
                  value: values[name],
                  error: errors[name] && touched[name],
                  helpertext: errors[name] && touched[name] && errors[name],
                  variant: "outlined",
                });

                return (
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={4} lg={3}>
                        <TextField
                          label="عنوان"
                          type="string"
                          onChange={handleChange}
                          {...onMakeFormikVariable("title")}
                        />
                      </Grid> 

                      <Grid item xs={12} md={6} lg={3}>
                        <DropdownPicker
                          label="نوع درخواست"
                          onChange={(event) => {
                            setFieldValue("levelKind", event.target.value);
                          }}
                          list={data.levelKind}
                          filterable={false}
                          {...onMakeFormikVariable("levelKind")}
                        />
                      </Grid>

                      <Grid item xs={12} md={3} lg={3}>
                        <FormControlLabel
                          name="isActive"
                          value={values.isActive}
                          checked={values.isActive}
                          onChange={handleChange}
                          control={<Switch />}
                          label="فعال"
                        />
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        {values.levelKind === "Content" && (
                          <TextField
                            label="توضیحات "
                            type="string"
                            onChange={handleChange}
                            {...onMakeFormikVariable("description")}
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={4} lg={3}>
                        {values.levelKind === "Content" && (
                          <TextField
                            label="قیمت"
                            type="number"
                            onChange={handleChange}
                            {...onMakeFormikVariable("price")} // Updated line
                          />
                        )}
                      </Grid>

                      <Grid item xs={12} md={12} lg={4}>
                     
                          <img
                            src={values.imageUrl}
                            alt="Preview"
                            id="imagePreview"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxWidth: "100px",
                              maxHeight: "100px",
                            }}
                          />
                     
                        <input
                          type="hidden"
                          id="imagePreviewHidden"
                          value={values.imageUrl}
                        ></input>

                        <input
                          type="hidden"
                          id="filePreviewHidden"
                          value={values.fileUrl}
                        ></input>

                        <input
                          type="hidden"
                          id="treeId"
                          value={treeId}
                          {...onMakeFormikVariable("treeId")}
                        ></input>

                        <input
                          type="hidden"
                          id="contentId"
                          {...onMakeFormikVariable("contentId")}
                          value={values.contentId}
                        ></input>
                      </Grid>

                      <Grid item xs={12} md={12}>
                        
                          <Dropzone
                            onChange={(incomingFiles) =>
                              updateFiles(incomingFiles, true)
                            }
                            label="تصویر مورد نظر را انتخاب کنید"
                            value={images}
                            multiple={true}
                            maxFiles={1}
                            style={{ direction: "ltr" }}
                            accept="image/*"
                          >
                            {images.length > 0 &&
                              images.map((file, index) => (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <HighlightOffIcon
                                    sx={{
                                      position: "absolute",
                                      left: 6,
                                      zIndex: 10,
                                    }}
                                    color="secondary"
                                    onClick={(e) => onRemoveFile(index, e)}
                                  />
                                  <FileMosaic {...file} preview />

                                  <div
                                    key={index}
                                    style={{ position: "relative" }}
                                  >
                                    {/* Existing code... */}
                                    {file.preview && (
                                      <img
                                        src={URL.createObjectURL(file.preview)}
                                        alt="Preview"
                                        style={{ maxWidth: "100%" }}
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                          </Dropzone>                        
                      </Grid>

                      <Grid item xs={12} md={12} lg={12}>
                        <div style={{}}>
                          {values.levelKind === "Content" && (
                            <CKEditor
                              editor={ClassicEditor}
                              data={values.content || ""}
                              config={{
                                placeholder:
                                  "متن مورد نظر را اینجا وارد کنید ...",
                                language: {
                                  ui: "fa", // Set the user interface language to Persian (Farsi)
                                  content: "fa", // Set the content language to Persian (Farsi)
                                  direction: "rtl", // Set the text direction to right-to-left (RTL)
                                },
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                setFieldValue("content", data);
                              }}
                            />
                          )}
                        </div>
                        {errors.content && touched.content && (
                          <div>{errors.content}</div>
                        )}
                      </Grid>

                      <Grid item xs={12} md={12}>
                        {values.levelKind === "Content" && (
                          <Dropzone
                            onChange={(incomingFiles) =>
                              updateFiles(incomingFiles, false)
                            }
                            label="فایل مورد نظر را انتخاب کنید"
                            value={files}
                            multiple={true}
                            maxFiles={2}
                            style={{ direction: "ltr" }}
                             
                          >
                            {files.length > 0 &&
                              files.map((file, index) => (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <HighlightOffIcon
                                    sx={{
                                      position: "absolute",
                                      left: 6,
                                      zIndex: 10,
                                    }}
                                    color="secondary"
                                    onClick={(e) => onRemoveFile(index, e)}
                                  />
                                  <FileMosaic {...file} preview />

                                  <div
                                    key={index}
                                    style={{ position: "relative" }}
                                  >
                                    {/* Existing code... */}
                                    {file.preview && (
                                      <img
                                        src={URL.createObjectURL(file.preview)}
                                        alt="Preview"
                                        style={{ maxWidth: "100%" }}
                                      />
                                    )}
                                  </div>
                                </div>
                              ))}
                          </Dropzone>
                        )}
                      </Grid>

                      <Grid item xs={12} md={12} lg={4}>
                        {values.levelKind === "Content" && (
                          <img
                            src={values.fileUrl}
                            alt="Preview"
                            id="imagePreview"
                            style={{
                              width: "100%",
                              height: "auto",
                              maxWidth: "100px",
                              maxHeight: "100px",
                            }}
                          />
                        )}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={12}
                        container
                        justifyContent={"flex-end"}
                      >
                        <Button
                          disabled={isSubmitting}
                          variant="outlined"
                          size="large"
                          color="gray"
                          sx={{ mx: 1 }}
                          onClick={onNavigateToListPage}
                        >
                          انصراف
                        </Button>

                        <Button
                          disabled={isSubmitting}
                          variant="contained"
                          type="submit"
                          size="large"
                        >
                          {loading && (
                            <CircularProgress size={16} color="inherit" />
                          )}
                          {roleId !== "create" ? "ویرایش" : "ایجاد"}
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                );
              }}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ContentModifyPage;
