import { useState } from "react";
import { Grid, Typography } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Brush,
} from "recharts";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ChartProvider from ".";
import LoadingWrapper from "../loading";

const BarChartCustom = ({
  title,
  xAxis = "x",
  xAxisTitle = "",
  yAxisTitle = "",
  data = [],
  series = [],
  loading = false,
  interval = 1,
  colors = ChartProvider.colors,
  showBrush = false,
  stackId = null,
}) => {
  const [chartProps, setChartProps] = useState(
    series.reduce(
      (a, b) => {
        a[b] = false;
        return a;
      },
      { hover: null }
    )
  );

  const handleLegendMouseEnter = (e) => {
    if (!chartProps[e.dataKey]) {
      setChartProps({ ...chartProps, hover: e.dataKey });
    }
  };

  const handleLegendMouseLeave = (e) => {
    setChartProps({ ...chartProps, hover: null });
  };

  const selectBar = (e) => {
    setChartProps({
      ...chartProps,
      [e.dataKey]: !chartProps[e.dataKey],
      hover: null,
    });
  };

  return (
    <Grid container style={{ direction: "ltr", height: showBrush ? 400 : 368 }}>
      {loading ? (
        <Grid
          item
          xs={12}
          md={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <LoadingWrapper />
        </Grid>
      ) : Array.isArray(data) && data.length > 0 ? (
        <>
          <Grid item xs={12} md={12}>
            <Typography textAlign={"center"} variant="h6" fontWeight={"bold"}>
              {title}
            </Typography>
          </Grid>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={364}
              data={data}
              margin={{
                top: 16,
                right: 0,
                left: 0,
                bottom: series && series.length > 1 ? 24 : 16,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={xAxis}
                label={{
                  value: xAxisTitle || "",
                  style: { textAnchor: "middle" },
                  position: "bottom",
                  offset: 36,
                }}
                angle={-15}
                fontSize={10}
                interval={interval}
                textAnchor="end"
              />
              {showBrush && (
                <Brush dataKey={xAxis} height={28} stroke="#8884d8" />
              )}
              <YAxis
                label={{
                  value: yAxisTitle || "",
                  style: { textAnchor: "middle" },
                  angle: -90,
                  position: "left",
                  offset: -8,
                }}
              />
              <Tooltip />
              {series && series.length > 1 ? (
                <Legend
                  onClick={selectBar}
                  onMouseOver={handleLegendMouseEnter}
                  onMouseOut={handleLegendMouseLeave}
                  verticalAlign="top"
                />
              ) : null}
              {series.map((ser, index) => (
                <Bar
                  key={index}
                  dataKey={ser}
                  stackId={stackId}
                  fill={ChartProvider.renderColor(colors, index)}
                  hide={chartProps[ser] === true}
                  fillOpacity={Number(
                    chartProps.hover === ser || !chartProps.hover ? 1 : 0.4
                  )}
                />
              ))}
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <Grid
          item
          xs={12}
          md={12}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <DashboardIcon sx={{ fontSize: "8rem", mb: 3 }} color="gray" />
          <Typography color="gray" variant="h6" fontWeight="bold">
            داده ای جهت نمایش وجود ندارد
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default BarChartCustom;
