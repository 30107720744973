import {
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import WestIcon from "@mui/icons-material/West";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useEffect, useState } from "react";
import MuiIcon from "../shared/icon/MuiIcon";

const MobileMenuItem = ({ menu, onCloseSidebar }) => {
  const location = useLocation();

  const [pathname, setPathname] = useState("");
  const [isShowSubMenu, setIsShowSubMenu] = useState(false);

  useEffect(() => {
    setPathname(location.pathname);
  }, [location]);

  const onClickMenuItem = async (event, menu) => {
    if (menu.child && Array.isArray(menu.child)) {
      event.preventDefault();
      setIsShowSubMenu(!isShowSubMenu);
    } else {
      onCloseSidebar();
    }
  };

  return (
    <ListItem
      sx={{ display: "block", px: 1, py: 0.5 }}
      className="menu-item-container"
    >
      <Link
        to={menu?.link}
        onClick={(event) => {
          onClickMenuItem(event, menu);
        }}
      >
        <ListItemButton
          className={clsx({
            "menu-item-button": true,
            active:
              menu?.link === "/"
                ? menu?.link === pathname
                : menu.level !== 2 && pathname.includes(menu?.link),
            open: true,
          })}
          sx={{
            justifyContent: "initial",
            px: 2,
            // border: isShowSubMenu && "1px solid",
          }}
          onClick={(event) => {
            onClickMenuItem(event, menu);
          }}
        >
          <ListItemIcon className="menu-icon">
            {menu.icon ? <MuiIcon name={menu.icon} /> : <WestIcon />}
          </ListItemIcon>

          <ListItemText primary={menu.title} className="text" />

          {menu.child && Array.isArray(menu.child) ? (
            <ListItemIcon
              className={clsx({
                "submenu-icon": true,
                rotate: isShowSubMenu,
              })}
              sx={{
                ml: 3,
              }}
            >
              <KeyboardArrowLeftIcon />
            </ListItemIcon>
          ) : null}
        </ListItemButton>
      </Link>

      {menu.child && Array.isArray(menu.child) ? (
        <Collapse
          in={isShowSubMenu}
          timeout="auto"
          unmountOnExit
          sx={{ mt: 0.5 }}
        >
          <List disablePadding className="submenu-list-container">
            {menu.child.map((submenu, index) => (
              <MobileMenuItem
                key={submenu.id}
                menu={submenu}
                onCloseSidebar={onCloseSidebar}
              />
            ))}
          </List>
        </Collapse>
      ) : null}
    </ListItem>
  );
};

export default MobileMenuItem;
