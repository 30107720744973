import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik"; 
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs"; 
 
import CMSService from "../../../../../infrastructure/api/cms";  

const validationSchema = Yup.object().shape({
 
  title: Yup.string().required("لطفا این فیلد را پر کنید."),  
  link: Yup.string().required("لطفا این فیلد را پر کنید."),  
  isActive:  Yup.bool(), 
 
});

const FooterLinkModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    navigate("/CMS/Management/FooterLink");
  };
  
  
    
  
  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try {  
      const res = await CMSService.modifyFooterLinkApi(sendData);
      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create" ? `ویرایش ${data?.footerLink?.title}` : "ایجاد"
            }
          />

          <CardContent>
            <Formik
              initialValues={data.footerLink}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,                
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
 
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="عنوان"
                        variant="outlined"
                        fullWidth
                        name="title"
                        type="string"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title && touched.title}
                        helperText={
                          errors.title && touched.title && errors.title
                        }
                      />
                    </Grid> 
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="لینک"
                        variant="outlined"
                        fullWidth
                        name="link"
                        type="string"
                        value={values.link}
                        onChange={handleChange}
                        error={errors.link && touched.link}
                        helperText={
                          errors.link && touched.link && errors.link
                        }
                      />
                    </Grid> 


                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isActive" 
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فعال"
                      />
                    </Grid>
  
                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                    </Grid>



                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={onNavigateToListPage}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default FooterLinkModifyPage;
