import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";

const TerminalSelect = ({
  name,
  value,
  label,
  onChange,
  terminalList,
  error,
  helperText,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel
        id="category-select-label"
        sx={{
          background: "#fff",
          padding: "0 10px",
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="category-select-label"
        id="category-select"
        name={name}
        value={value}
        onChange={onChange}
        error={error}
      >
      
        {terminalList.map((x) => (
          <MenuItem key={x.id} value={x.id}>
            {x.title}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default TerminalSelect;
