import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField, // Import the TextField component
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ModalTransition } from "../shared/Dialog/Config";
import { toast } from "react-toastify";
import EnhancedTable from "../shared/table";
import SHOPService from "../../../infrastructure/api/shop"; 

const PriceHistoryModal = ({
  product,
  productId,
  open,
  onClose,
  data  
}) => {
  const [tableData, setTableData] = useState({
    columns: [
      {
        id: "createOn",
        label: "تاریخ",
        type: "date"
      },
      {
        id: "price",
        label: "قیمت",
        render: (row) => <span>{row.price}</span> // Render the price as text
      },
      {
        id: "description",
        label: "توضیحات",
      }
    ],
    data: [],
  });

  const [priceInput, setPriceInput] = useState(""); // State for the input field

  useEffect(() => {
    if (!open) {
      setTableData((old) => ({ ...old, data: [] }));
    } else {
      setTableData((old) => ({ ...old, data: data.priceHistory }));
    }
  }, [open, data]);

  const handleAddPrice = async () => {
    const price = parseFloat(priceInput); // Parse the input to a float value
 
    if (isNaN(price) || price <= 0) {
       
      toast.error("قیمت را وارد نمایید");
      return;
    }

    const addPriceData = {
      productId: productId,
      price: price,
      description:'test'
    };
   var res = await SHOPService.addProductPriceApi(addPriceData);
 
     if(res.isSuccess)
     {
      toast.success(res.message);
     }
     else
     {      
      toast.error(res.message);
     }
    setPriceInput(""); // Clear the input field after adding the price
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={ModalTransition}
      keepMounted
      onClose={onClose}
      maxWidth={"lg"}
      className="dialog-wrapper"
    >
      <DialogTitle>{`سابقه تغیر قیمت "${product}" `}</DialogTitle>

      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={12}>
            <EnhancedTable
              showFilter={false}
              rowKey={"id"}
              rows={tableData.data}
              headCells={tableData.columns}
              pageSize={20}
              showPaging={true}
            />
          </Grid>
        </Grid>
        <TextField
          label="قیمت"
          variant="outlined"
          fullWidth
          value={priceInput}
          onChange={(e) => setPriceInput(e.target.value)}
          type="number"
          inputProps={{ min: 0 }}
        />
      </DialogContent>

      <DialogActions className="buttons-wrapper">
        <Button
          variant="outline"
          size="large"
          className="cancel-button"
          onClick={onClose}
        >
          بستن
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleAddPrice}
        >          افزودن قیمت
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PriceHistoryModal;
