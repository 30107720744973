import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { List } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import MenuItem from "./MenuItem";
import { getIsShowModuleSidebar } from "../../../application/selectors/ui";
import { setIsShowModuleSideBar } from "../../../application/slices/ui";
import { getUserMenuList } from "../../../application/selectors/user";

const ModuleSideBar = () => {
  const menuList = useSelector(getUserMenuList);
  const isShowModuleSideBar = useSelector(getIsShowModuleSidebar);

  const dispatch = useDispatch();
  const location = useLocation();
  const [open] = useState(true);
  const [subMenu, setSubMenu] = useState([]);

  useEffect(() => {
    const pathnames = location.pathname.split("/").filter((x) => x);

    if (pathnames.length >= 2 && Array.isArray(menuList)) {
      if (
        pathnames.find((x) => x === "dashboard") ||
        location.pathname === "/account/resetpassword"
      ) {
        dispatch(setIsShowModuleSideBar(false));
      } else {
        dispatch(setIsShowModuleSideBar(true));

        const specificNode = onFindSpecificNode(pathnames.splice(0, 2), {
          child: menuList,
        });
        setSubMenu(specificNode.child);
      }
    } else {
      dispatch(setIsShowModuleSideBar(false));
    }
  }, [dispatch, location, menuList]);

  const onFindSpecificNode = (pathnames, menuList) => {
    let specificNode = menuList;

    pathnames.forEach((path) => {
      const item = specificNode.child.find((menu) => menu.oldLink === path);

      if (item) {
        specificNode = item;
      }
    });

    return specificNode;
  };

  return isShowModuleSideBar ? (
    <div
      className={clsx({
        "module-sidebar-container": true,
        hide: !isShowModuleSideBar,
      })}
    >
      <List>
        {React.Children.toArray(
          subMenu.map((item) => (
            <MenuItem
              key={item.id}
              menu={item}
              isOpenedSideBar={open}
              setIsOpenedSideBar={() => {}}
            />
          ))
        )}
      </List>
    </div>
  ) : null;
};

export default ModuleSideBar;
