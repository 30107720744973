import React from "react";

const Landing = () => {
  return (
    <>
      <h2>Landing</h2>
    </>
  );
};

export default Landing;
