import React from "react";
import { Outlet } from "react-router-dom";
import { Grid } from "@mui/material";

const AuthLayout = () => {
  return (
    <Grid
      container
      className="auth-container"
      alignItems={"center"}
      justifyContent={"center"}
    >
      <div className="auth-background-image-container" />

      <Grid item xs={11} md={6} lg={4} xl={3} style={{ zIndex: 9 }}>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default AuthLayout;
