import axios from "axios";
import { refreshToken } from "../../application/slices/auth";
import store from "../../application/store";

const baseURL = window.globalConfig?.API_URL
  ? window.globalConfig?.API_URL
  : process.env["API_URL"] || "https://api.tesco.co.kr/";
// const baseURL = process.env["API_URL"] || "http://localhost:5050/";

export const authHeader = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.access_token) {
    // for Node.js Express back-end
    return { Authorization: "Bearer " + user.access_token };
  } else {
    return {};
  }
};

export const getLocalRefreshToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user && user.refresh_token) {
    // for Node.js Express back-end
    return user.refresh_token;
  } else {
    return "";
  }
};

export const api = axios.create({
  baseURL: baseURL,
});

// defining a custom error handler for all APIs
const errorHandler = async (error) => {
  const statusCode = error.response?.status;
  const originalConfig = error.config;

  if (error.code === "ERR_CANCELED") {
    // notification.error({
    //   placement: "bottomRight",
    //   description: "API canceled!",
    //   message: "API canceled!",
    // });
    return Promise.resolve();
  }

  // logging only errors that are not 401
  if (originalConfig.url !== "account/RefreshToken" && error.response) {
    if (statusCode === 401 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        // const rs = await api.request({
        //   url: `account/RefreshToken`,
        //   method: "POST",
        //   data: {
        //     RefreshToken: getLocalRefreshToken(),
        //   },
        // });
        // const user = rs.data;
        // window.localStorage.setItem("user", user);
        store.dispatch(refreshToken());
        return api(originalConfig);
      } catch (_error) {
        if (_error.response && _error.response.data) {
          return Promise.reject(_error.response.data);
        }

        localStorage.removeItem("user");
        return Promise.reject(_error);
      }
    }
  } else {
    if (originalConfig.url === "account/RefreshToken") {
      localStorage.removeItem("user");
      // window.location.reload();
    }
    return Promise.reject(error);
  }

  return Promise.reject(error);
};

// registering the custom error handler to the
// "api" axios instance
api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

export default function setupAxios(axios, store, props) {}
