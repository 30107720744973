import { Grid } from "@mui/material";
import { useLoaderData } from "react-router-dom";
import BreadcrumbsComponent from "../../../components/shared/breadcrumbs";
import Stat from "../../../components/shared/stat";

const SHOPManagementPage = () => {
  const dataList = useLoaderData("root");

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      {dataList
        .sort((a, b) => a.priority - b.priority)
        .map((item) => (
          <Grid xs={12} md={6} lg={3} item key={item.priority}>
            <Stat
              type={item.color}
              value={item.count}
              unit=""
              label={item.title}
              date={item.lastUpdate}
              dateLabel="آخرین بروزرسانی"
              isTime={false}
              hasShowMore={true}
              showMore={{ link: item.link || "", text: "اطلاعات بیشتر" }}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default SHOPManagementPage;
