import { authHeader, api } from "../configs/axiosConfigs";
import { defineCancelApiObject } from "../configs/axiosUtils";

const getMenuData = async (cancel = false) => {
  const response = await api.request({
    url: `menu/GetMenuData`,
    method: "GET",
    signal: cancel
      ? cancelApiObject[this.get.name].handleRequestCancellation().signal
      : undefined,
    headers: authHeader(),
  });

  return response.data;
};

const getModule = async (cancel = false) => {
  const response = await api.request({
    url: `menu/GetModule`,
    method: "GET",
    signal: cancel
      ? cancelApiObject[this.get.name].handleRequestCancellation().signal
      : undefined,
    headers: authHeader(),
  });

  return response.data;
};

const userService = {
  getMenuData,
  getModule,
};

const cancelApiObject = defineCancelApiObject(userService);

export default userService;
