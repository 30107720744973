// const colors = [
//   "#8884d8",
//   "#ffc658",
//   "#82ca9d",
//   "#163ba2",
//   "#3cbc6f",
//   "#FF6A6A",
//   "#3C8DBC",
//   "#808080",
// ];

const colors = [
  "#163ba2",
  "#ffb05a",
  "#8884d8",
  "#fa835a",
  "#82ca9d",
  "#fe995b",
  "#8fd6ed",
  "#f26d58",
  "#3f71fc",
  "#e95756",
  "#2c5d85",
  "#dc4352",
  "#005e21",
  "#cd2e4d",
  "#808080",
  "#bc1b48",
  "#ccc",
  "#a90841",
  "#65c888",
  "#93003a",
];

const renderColor = (colors, index) => {
  if (index + 1 > colors.length) {
    index = index % colors.length;
  }
  return colors[index];
};

const ChartProvider = {
  colors,
  renderColor,
};

export default ChartProvider;
