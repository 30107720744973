import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../components/shared/breadcrumbs";
import UserManagementService from "../../../../infrastructure/api/userManagement";
import { Box } from "@mui/system";

import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddBoxIcon from "@mui/icons-material/AddBox";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import FolderIcon from "@mui/icons-material/Folder";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";

const validationSchema = Yup.object().shape({
  roleName: Yup.string().required("لطفا این فیلد را پر کنید."),
  roleDescription: Yup.string().required("لطفا این فیلد را پر کنید."),
  isActive: Yup.bool(),
});

const icons = {
  check: <CheckBoxIcon color="primary" />,
  uncheck: <CheckBoxOutlineBlankIcon color="gray" />,
  halfCheck: <IndeterminateCheckBoxIcon color="info" />,
  expandClose: <ChevronLeftIcon color="black" />,
  expandOpen: <KeyboardArrowDownIcon color="black" />,
  expandAll: <AddBoxIcon color="primary" />,
  collapseAll: <IndeterminateCheckBoxIcon color="info" />,
  parentClose: <FolderIcon color="gray" />,
  parentOpen: <FolderOpenIcon color="gray" />,
  leaf: <InsertDriveFileIcon color="gray" />,
};

const onMakePermissionNodes = (nodes, roleId) => {
  if (Array.isArray(nodes)) {
    let checked = [],
      expanded = [];

    const array = nodes.map((node) => {
      const newNode = {
        value: node.id,
        label: node.title,
      };

      if (node.level !== 3) {
        const {
          nodes: children,
          checked: newChecked,
          expanded: newExpanded,
        } = onMakePermissionNodes(node.child, roleId);

        newNode.children = children;
        checked = [...checked, ...newChecked];
        expanded = [...expanded, ...newExpanded];

        // if (newExpanded.length > 0) {
        //   expanded.push(node.id);
        // }
      } else {
        newNode.children = [
          {
            value: `show-${node.id}`,
            label: "نمایش",
            locationId: node.id,
            roleId: roleId,
            icon: <PreviewIcon color="primary" />,
          },
          {
            value: `create-${node.id}`,
            label: "ایجاد",
            locationId: node.id,
            roleId: roleId,
            icon: <AddIcon color="success" />,
          },
          {
            value: `edit-${node.id}`,
            label: "ویرایش",
            locationId: node.id,
            roleId: roleId,
            icon: <EditIcon color="navy" />,
          },
          {
            value: `delete-${node.id}`,
            label: "حذف",
            locationId: node.id,
            roleId: roleId,
            icon: <DeleteIcon color="danger" />,
          },
        ];

        if (node?.actionAccess) {
          if (node.actionAccess.show) {
            checked.push(`show-${node.id}`);
          }

          if (node.actionAccess.create) {
            checked.push(`create-${node.id}`);
          }

          if (node.actionAccess.edit) {
            checked.push(`edit-${node.id}`);
          }

          if (node.actionAccess.delete) {
            checked.push(`delete-${node.id}`);
          }

          if (checked.length > 0) {
            expanded.push(node.id);
          }
        }
      }
      return newNode;
    });

    return { nodes: array, checked, expanded };
  }
};

const onFindNodeItem = (nodes, key) => {
  if (key) {
    let i,
      result = null;

    for (i = 0; result == null && i < nodes.length; i++) {
      if (nodes[i].value === key) {
        result = nodes[i];
      } else if (nodes[i].children) {
        result = onFindNodeItem(nodes[i].children, key);
      }
    }
    return result;
  }
};

const RoleInfoModifyPage = () => {
  const roleInfoDetail = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(
    onMakePermissionNodes(roleInfoDetail.permission, roleId)
  );
  // ***************** [ Tree Functions ] *****************
  const onCheckTreeItem = (checked) => {
    setState((old) => ({ ...old, checked }));
  };

  const onExpandTreeParent = (expanded) => {
    setState((old) => ({ ...old, expanded }));
  };

  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const data = {
      role: { ...roleInfoDetail.role, ...formValue },
      permission: [],
    };

    let permissions = [];

    for (let i = 0; i < state.checked.length; i++) {
      const selected = state.checked[i];
      const [mode, key] = selected.split("-");
      const foundNode = onFindNodeItem(state.nodes, key);

      if (foundNode) {
        // debugger
        const index = permissions.findIndex(
          (x) => x.locationId === foundNode.value
        );
        if (index > -1) {
          permissions[index][mode] = true;
        } else {
          permissions.push({
            roleId: roleId !== "create" ? roleId : null,
            locationId: foundNode.value,
            show: mode === "show",
            create: mode === "create",
            edit: mode === "edit",
            delete: mode === "delete",
          });
        }
      }
    }
    data.permission = permissions;
    try {
      const res = await UserManagementService.modifyRoleInfoApi(data);

      if (res.isSuccess) {
        toast.success(res.message);
        navigate("/UM/Yeksa/RoleInfo");
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error("خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create"
                ? `ویرایش ${roleInfoDetail.role.roleName}`
                : "ایجاد"
            }
          />

          <CardContent>
            <Formik
              initialValues={roleInfoDetail.role}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="عنوان"
                        variant="outlined"
                        fullWidth
                        name="roleName"
                        type="string"
                        value={values.roleName}
                        onChange={handleChange}
                        error={errors.roleName && touched.roleName}
                        helperText={
                          errors.roleName && touched.roleName && errors.roleName
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="شرح"
                        variant="outlined"
                        fullWidth
                        name="roleDescription"
                        type="roleDescription"
                        value={values.roleDescription}
                        onChange={handleChange}
                        error={
                          errors.roleDescription && touched.roleDescription
                        }
                        helperText={
                          errors.roleDescription &&
                          touched.roleDescription &&
                          errors.roleDescription
                        }
                      />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isActive"
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فعال"
                      />
                    </Grid>

                    <Grid item xs={12} md={12}>
                      <Paper elevation={3} sx={{ p: 2 }}>
                        <Box sx={{ mb: 1 }}>
                          <Typography variant="title" fontWeight={"bold"}>
                            دسترسی‌ها
                          </Typography>
                        </Box>
                        <Box>
                          <CheckboxTree
                            nodes={state.nodes}
                            checked={state.checked}
                            expanded={state.expanded}
                            onCheck={onCheckTreeItem}
                            onExpand={onExpandTreeParent}
                            icons={icons}
                            showExpandAll
                          />
                        </Box>
                      </Paper>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={() => {
                          navigate("/UM/Yeksa/RoleInfo");
                        }}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RoleInfoModifyPage;
