import { Grid, Typography } from "@mui/material";
import {
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Legend,
} from "recharts";
import DashboardIcon from "@mui/icons-material/Dashboard";

import ChartProvider from ".";
import LoadingWrapper from "../loading";

const PieChartCustom = ({
  title,
  data = [],
  loading = false,
  colors = ChartProvider.colors,
  showBrush = false,
}) => {
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    name,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}% :${name}`}
      </text>
    );
  };

  return (
    <Grid container style={{ direction: "ltr", height: showBrush ? 400 : 368 }}>
      {loading ? (
        <Grid
          item
          xs={12}
          md={12}
          container
          justifyContent="center"
          alignItems="center"
        >
          <LoadingWrapper />
        </Grid>
      ) : Array.isArray(data) && data.length > 0 ? (
        <>
          <Grid item xs={12} md={12}>
            <Typography textAlign={"center"} variant="h6" fontWeight={"bold"}>
              {title}
            </Typography>
          </Grid>
          <ResponsiveContainer width="100%" height="100%">
            <PieChart
              width={500}
              height={364}
              data={data}
              margin={{
                top: 16,
                right: 0,
                left: 12,
                bottom: 16,
              }}
            >
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={"90%"}
                fill="#8884d8"
                dataKey={"value"}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={ChartProvider.renderColor(colors, index)}
                  />
                ))}
              </Pie>
              <Legend verticalAlign="top" />
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </>
      ) : (
        <Grid
          item
          xs={12}
          md={12}
          container
          justifyContent="center"
          alignItems="center"
          direction="column"
        >
          <DashboardIcon sx={{ fontSize: "8rem", mb: 3 }} color="gray" />
          <Typography color="gray" variant="h6" fontWeight="bold">
            داده ای جهت نمایش وجود ندارد
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default PieChartCustom;
