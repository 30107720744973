import React from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Hidden,
} from "@mui/material";
import Icon from "../shared/icon";
// import HomeBannerImage from "../../assets/images/home-banner.png";
import LoadingWrapper from "../shared/loading";
import { useSelector } from "react-redux";
import { getIsLoading } from "../../../application/selectors/ui";
import Stat from "../shared/stat";
import EnhancedTable from "../shared/table";
import PieChartCustom from "../shared/chart/PieChartCustom";
import BarChartCustom from "../shared/chart/BarChartCustom";
import LineChartCustom from "../shared/chart/LineChartCustom";

const Dashboard = ({ menuList, cartTitle, blockList = [] }) => {
  const isLoading = useSelector(getIsLoading);
  console.log("blockList", blockList);

  const onRenderDashboardBlock = (item) => {
    switch (item.blockType) {
      case "Box":
        return (
          <Stat
            type={item?.boxData?.color}
            value={item?.boxData?.value}
            unit=""
            label={item?.boxData?.title}
            date={item?.boxData?.lastUpdate}
            dateLabel="آخرین بروزرسانی"
            isTime={false}
            hasShowMore={true}
            showMore={{
              link: item?.boxData?.link || "",
              text: "اطلاعات بیشتر",
            }}
          />
        );

      case "Table":
        const table = item.tableData;

        const tableColumns = table.columns.map((x) => ({
          id: x,
          label: x,
        }));
        const tableData = table.data.map((row, index) => {
          let item = {};

          for (let i = 0; i < row.length; i++) {
            item[table.columns[i]] = row[i];
          }
          return item;
        });

        return (
          <Card>
            <CardHeader title={item?.tableData?.title} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <EnhancedTable
                    rowKey={"id"}
                    rows={tableData}
                    headCells={tableColumns}
                    pageSize={10}
                    showFilter={false}
                    showIndex={false}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      case "Chart":
        const chartData = item?.chartData;
        let data = [];

        data = chartData.xValues.map((item) => ({ x: item }));

        for (let i = 0; i < chartData.data.length; i++) {
          const yData = chartData.data[i];
          const sereTitle = chartData.seriesTitle[i];

          for (let j = 0; j < yData.length; j++) {
            data[j][sereTitle] = yData[j];
          }
        }

        let output = <Alert severity="warning">این نوع چارت وجود ندارد!</Alert>;

        switch (chartData.chartType) {
          case "Line":
            output = (
              <LineChartCustom
                title={chartData.title}
                xAxisTitle={chartData.xAxisTitle}
                yAxisTitle={chartData.yAxisTitle}
                data={data}
                series={chartData.seriesTitle}
                showZoomOutButton={true}
              />
            );
            break;
          case "Pie":
            const pie = chartData.seriesTitle
              .map((ser) => ({
                name: ser,
                value: parseFloat(
                  data
                    .reduce((total, obj) => (total += obj[ser]), 0)

                    .toFixed(2)
                ),
              }))
              .filter((x) => x.value >= 0);

            output = <PieChartCustom title={chartData.title} data={pie} />;
            break;
          case "Column":
            output = (
              <BarChartCustom
                title={chartData.title}
                xAxisTitle={chartData.xAxisTitle}
                yAxisTitle={chartData.yAxisTitle}
                data={data}
                series={chartData.seriesTitle}
                showZoomOutButton={true}
              />
            );
            break;

          default:
            break;
        }

        return (
          <Card>
            <CardHeader title={item?.chartData?.title} />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  {output}
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );

      default:
        return null;
    }
  };
  return (
    <Grid container className="home-container" spacing={3}>
      {/* <Hidden smDown>
        <Grid item xs={12} md={12}>
          <img
            src={HomeBannerImage}
            alt="dashboard-banner"
            className="banner-image"
          />
        </Grid>
      </Hidden> */}

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader title={cartTitle} />
          <CardContent>
            <Grid container spacing={3}>
              {React.Children.toArray(
                Array.isArray(menuList) &&
                  menuList
                    .filter((x) => x.title !== "داشبورد")
                    .map((menu) => (
                      <Grid item xs={6} sm={4} md={3} lg={2}>
                        <Link to={menu.link}>
                          <div className={"stat"}>
                            <div className="stat-icon-container">
                              <Icon name={menu.icon} link={menu.link} />
                            </div>
                            <div className="stat-detail-container">
                              <div className="stat-title">{menu.title}</div>
                              <div className="stat-description">
                                {menu.description || ""}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </Grid>
                    ))
              )}

              {isLoading && (
                <Grid item xs={12} md={12}>
                  <LoadingWrapper />
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      {Array.isArray(blockList) && blockList.length > 0
        ? blockList
            .sort((a, b) => a.priority - b.priority)
            .map((item, index) => (
              <Grid
                item
                key={`${cartTitle}-${index}`}
                xs={item.xs}
                sm={item.sm}
                md={item.map}
                lg={item.lg}
              >
                {onRenderDashboardBlock(item)}
              </Grid>
            ))
        : null}
    </Grid>
  );
};

export default Dashboard;
