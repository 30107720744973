import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import { Link, useLoaderData, useNavigate } from "react-router-dom";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";

import BreadcrumbsComponent from "../../../components/shared/breadcrumbs";
import EnhancedTable from "../../../components/shared/table";
import DeleteConfirm from "../../../components/shared/Dialog/DeleteConfirm";
import UserManagementService from "../../../../infrastructure/api/userManagement";

const RoleInfoPage = () => {
  const roleInfoList = useLoaderData("root");
  let navigate = useNavigate();
  const [deleteConfirm, setDeleteConfirm] = useState({
    open: false,
    data: {},
  });
  const [cascadeDelete, setCascadeDelete] = useState(false);
  const headCells = [
    {
      id: "roleId",
      disablePadding: false,
      label: "ایدی",
    },
    {
      id: "roleName",
      disablePadding: false,
      label: "عنوان",
    },
    {
      id: "updateOn",
      type: "date",
      disablePadding: true,
      label: "تاریخ بروزرسانی",
    },
    {
      id: "isActive",
      type: "boolean",
      disablePadding: false,
      label: "وضعیت",
    },
    {
      id: "roleDescription",
      numeric: false,
      disablePadding: false,
      label: "شرح",
    },
  ];

  const onEditClicked = (row) => {
    navigate(`${row.roleId}`);
  };

  const onDeleteClicked = (row) => {
    console.log("row", row);

    setDeleteConfirm((old) => ({ ...old, open: true, data: row }));
  };

  const confirmedDeleteRoleInfo = () => {
    console.log("cascadeDelete", cascadeDelete);
    return UserManagementService.deleteRoleInfoApi({
      id: deleteConfirm.data.roleId,
      cascadeDelete,
    });
  };

  const onFetchData = () => {
    navigate();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />

        <DeleteConfirm
          message={`برای حذف دیتای "${deleteConfirm.data.roleName}" اطمینان دارید؟`}
          open={deleteConfirm.open}
          onClose={(loadAgain) => {
            setDeleteConfirm({
              open: false,
              data: {},
            });
            setCascadeDelete(false);
            if (loadAgain) {
              navigate();
            }
          }}
          onSubmit={confirmedDeleteRoleInfo}
        >
          <Grid
            item
            xs={12}
            md={12}
            container
            justifyContent={"center"}
            sx={{ my: 1 }}
          >
            <FormControlLabel
              name="cascadeDelete"
              checked={cascadeDelete}
              onChange={(event) => {
                setCascadeDelete(event.target.checked);
              }}
              control={<Switch color="info" />}
              label="حذف کاربران زیرمجموعه این گروه"
            />
          </Grid>
        </DeleteConfirm>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title="گروه کاربری"
            action={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                <Link to={"create"}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    ایجاد
                  </Button>
                </Link>

                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1 }}
                />

                <IconButton
                  variant="text"
                  color="primary"
                  onClick={onFetchData}
                >
                  <SyncIcon />
                </IconButton>
              </Box>
            }
          />

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <EnhancedTable
                  rowKey={"roleId"}
                  rows={roleInfoList}
                  headCells={headCells}
                  pageSize={10}
                  actions={[
                    {
                      icon: <EditIcon sx={{ width: "1rem", height: "1rem" }} />,
                      onClick: onEditClicked,
                      color: "primary",
                    },
                    {
                      icon: (
                        <DeleteIcon sx={{ width: "1rem", height: "1rem" }} />
                      ),
                      onClick: onDeleteClicked,
                      color: "secondary",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RoleInfoPage;
