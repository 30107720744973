import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material";
import store from "./application/store";
import App from "./presentation";
import theme from "./presentation/common/theme";
import RTL from "./presentation/common/theme/CacheProvider";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./presentation/assets/fonts/dana/Farsi numerals/Webfonts/css/fontiran.css";
import "./presentation/assets/style/index.scss";

 
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <RTL>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
          <App />
        </Provider>
      </ThemeProvider>
    </RTL>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
