import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import { useLoaderData, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import { toast } from "react-toastify";

import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import DefaultApiService from "../../../../../infrastructure/api/default";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SHOPService from "../../../../../infrastructure/api/shop";
import SHOPCategorySelect from "../../../../components/shop/CategorySelect";
import SHOPBrandSelect from "../../../../components/shop/BrandSelect";

const validationSchema = Yup.object().shape({
 
  categoryId: Yup.number().required("لطفا این فیلد را پر کنید."),
  brandId: Yup.number().required("لطفا این فیلد را پر کنید."),
  title: Yup.string().required("لطفا این فیلد را پر کنید."),
  productCode: Yup.string().required("لطفا این فیلد را پر کنید."),
  keywords:Yup.string().required("لطفا این فیلد را پر کنید."),  
  isActive: Yup.bool(),
  description: Yup.string().required("لطفا این فیلد را پر کنید."),
    
});

const ProductModifyPage = () => {
  const data = useLoaderData("root");
  let navigate = useNavigate();
  const { id: roleId } = useParams();

  const [loading, setLoading] = useState(false);

  const onNavigateToListPage = () => {
    navigate("/SHOP/Management/Product");
  };
 
  const [files, setFiles] = React.useState([]);
 
   
  const updateFiles = async (incomingFiles) => {
    try {
      if (incomingFiles.length > 0) {
        const lastIndex = incomingFiles.length - 1;
        const lastFile = incomingFiles[lastIndex];

        if (lastFile.valid) {
          const result = await DefaultApiService.uploadFile(
            lastFile.file,
            "SHOP"
          );
          incomingFiles[lastIndex] = { ...lastFile, ...result.data };

          if (result.isSuccess) {
            toast.success("فایل با موفقیت آپلود شد.");  
             
          } else {
            incomingFiles[lastIndex].valid = false;
            incomingFiles[lastIndex].errors = [result.message];

            toast.error(result.message);
          }
        }
      }
      console.log("incomingFiles", incomingFiles);
      setFiles(incomingFiles);

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");
    }
  };

  const onRemoveFile = (fileIndex, event) => {
    event.preventDefault();
    event.stopPropagation();

    const newFiles = [...files];
    newFiles.splice(fileIndex, 1);
    setFiles(newFiles);
  };

  const onUploadExchangeFile = async () => {
    setLoading(true);

    try {
      const res = await DefaultApiService.postApi(
        "EM/GetData/UploadExchange/Upload",
        files[0]
      );

      if (res.isSuccess) {
        toast.success(res.message);
        setFiles([]);
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
    }
  };



  // Submit Action
  const onSubmit = async (formValue, { setSubmitting }) => {
    setLoading(true);
    const sendData = { ...data, ...formValue };

    try { 

      const guid_element = document.getElementById("GUID");
      if(guid_element.value!='')
      {
        sendData.guid= (guid_element.value);
      } 
      

       if(files[0]!=null)
       {
        sendData.url=files[0].directory
       }
       else
       {
        const element = document.getElementById("imagePreviewHidden");
        sendData.url= (element.value.substring(element.value.indexOf("/Files/")));

       }

      console.log(sendData);
      
      const res = await SHOPService.modifyProductApi(
        sendData
      );

      if (res.isSuccess) {
        toast.success(res.message);
        onNavigateToListPage();
      } else {
        toast.error(res.message);
      }

      setLoading(false);
    } catch (error) {  
      toast.error(error?.response?.data?.message || "خطایی رخ داده است.");

      setLoading(false);
      setSubmitting(false);
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title={
              roleId !== "create" ? `ویرایش ${data?.product?.title}` : "ایجاد"
            }
          />

          <CardContent>
            <Formik
              initialValues={data.product}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>


                    
                  <Grid item xs={12} md={6} lg={4}>
                      <SHOPCategorySelect
                        label="دسته بندی"
                        value={values.categoryId}
                        onChange={(event) => {
                          setFieldValue("categoryId", event.target.value);
                        }}
                        categoryList={data.categoryList}
                        error={errors.categoryId && touched.categoryId}
                        helperText={
                          errors.categoryId &&
                          touched.categoryId &&
                          errors.categoryId
                        }
                      />
                    </Grid>

                    
<Grid item xs={12} md={6} lg={4}>
    <SHOPBrandSelect
      label="برند"
      value={values.brandId}
      onChange={(event) => {
        setFieldValue("brandId", event.target.value);
      }}
      brandList={data.brandList}
      error={errors.brandId && touched.brandId}
      helperText={
        errors.brandId &&
        touched.brandId &&
        errors.brandId
      }
    />
  </Grid>



                    <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="عنوان"
                        variant="outlined"
                        fullWidth
                        name="title"
                        type="string"
                        value={values.title}
                        onChange={handleChange}
                        error={errors.title && touched.title}
                        helperText={
                          errors.title && touched.title && errors.title
                        }
                      />
                    </Grid>


                      <Grid item xs={12} md={6} lg={4}>
                      <TextField
                        label="کد کالا"
                        variant="outlined"
                        fullWidth
                        name="productCode"
                        type="string"
                        value={values.productCode}
                        onChange={handleChange}
                        error={errors.productCode && touched.productCode}
                        helperText={
                          errors.productCode && touched.productCode && errors.productCode
                        }
                      />
                    </Grid>



<Grid item xs={12} md={12} lg={4}>
  <TextField
    label="کلمات کلیدی"
    variant="outlined"
    fullWidth
    name="keywords"
    type="string"
    value={values.keywords}
    onChange={handleChange}
    error={errors.keywords && touched.keywords}
    helperText={
      errors.keywords && touched.keywords && errors.keywords
    }
  />
</Grid> 
 

                    <Grid item xs={12} md={12} lg={4}>
                      <TextField
                        label="توضیحات"
                        variant="outlined"
                        fullWidth
                        name="description"
                        type="string"
                        value={values.description}
                        onChange={handleChange}
                        error={errors.description && touched.description}
                        helperText={
                          errors.description && touched.description && errors.description
                        }
                      />
                    </Grid> 
  
                    

                    <Grid item xs={12} md={12} lg={4}> 

<img src={values.url } alt="Preview" id="imagePreview"  style={{
          width: "100%",
          height: "auto",
          maxWidth: "100px",
          maxHeight: "100px",
        }}/>

        <input type="hidden" id="imagePreviewHidden" value={values.url}></input>
        <input type="hidden" id="GUID" value={values.guid}></input>


</Grid>

 

                    <Grid item xs={12} md={6} lg={4}>
                      <FormControlLabel
                        name="isActive"
                        value={values.isActive}
                        checked={values.isActive}
                        onChange={handleChange}
                        control={<Switch />}
                        label="فعال"
                      />
                    </Grid>
 
                    
                    <Grid item xs={12} md={12}>
                      <Dropzone
                        onChange={updateFiles}
                        value={files}
                        multiple={false}
                        maxFiles={1}
                        style={{ direction: "ltr" }}
                        accept="image/*"
                      >
                        {files.length > 0 &&
                          files.map((file, index) => (
                            <div key={index} style={{ position: "relative" }}>
                              <HighlightOffIcon
                                sx={{
                                  position: "absolute",
                                  left: 6,
                                  zIndex: 10,
                                }}
                                color="secondary"
                                onClick={(e) => onRemoveFile(index, e)}
                              />
                              <FileMosaic {...file} preview />


                              <div key={index} style={{ position: "relative" }}>
                          {/* Existing code... */}
                          {file.preview && (
                            <img
                              src={URL.createObjectURL(file.preview)}
                              alt="Preview"
                              style={{ maxWidth: '100%' }}
                            />
                          )}
                        </div>

                        
                            </div>
                          ))}
                      </Dropzone>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      
                    </Grid>





                    <Grid
                      item
                      xs={12}
                      md={12}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Button
                        disabled={isSubmitting}
                        variant="outlined"
                        size="large"
                        color="gray"
                        sx={{ mx: 1 }}
                        onClick={onNavigateToListPage}
                      >
                        انصراف
                      </Button>

                      <Button
                        disabled={isSubmitting}
                        variant="contained"
                        type="submit"
                        size="large"
                      >
                        {loading && (
                          <CircularProgress size={16} color="inherit" />
                        )}
                        {roleId !== "create" ? "ویرایش" : "ایجاد"}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ProductModifyPage;
