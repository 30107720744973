import moment from "jalali-moment";

export const guid = () => {
  var S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  };
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};

export const randomArrayShuffle = (array) => {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};

export const toJalaliDate = (date) => {
  return date && date !== "0001-01-01T00:00:00"
    ? moment(date).format("jYYYY-jMM-jDD")
    : "";
};

export const toMiladyDate = (date) => {
  return date && date !== "0001-01-01T00:00:00"
    ? moment(date).format("YYYY-MM-DD") + " 00:00:00"
    : null;
};

export const toMiladyFullDate = (date) => {
  return date && date !== "0001-01-01T00:00:00"
    ? moment(date).format("YYYY-MM-DD HH:mm:ss")
    : null;
};

export const toJalaliDayDate = (date) => {
  return date ? moment(date).format("jD") : "";
};

export const toJalaliFullDate = (date) => {
  return date && date !== "0001-01-01T00:00:00"
    ? moment(date).format("jYYYY-jMM-jDD hh:mm:ss")
    : "";
};

export const toJalaliMonthName = (date) => {
  return date
    ? new Intl.DateTimeFormat("fa-IR", { month: "short" }).format(date)
    : "";
};

export const currencyFormat = (num) => {
  if (isNaN(num)) {
    return num;
  } else {
    return "" + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
};

export const numberParser = (value) =>
  parseFloat(value.replace(/\$\s?|(,*)/g, ""));

export const numberFormatter = (value) => {
  return value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const getLastDateOfMonthInfo = (date) => {
  const currentPersianDate = moment(date).locale("fa").add(1, "month");
  const firstDayOfNextMonthSt =
    currentPersianDate.format("jYYYY") +
    "/" +
    currentPersianDate.format("jMM") +
    "/01";
  //and then
  const firstDayOfNextMonth = moment.from(
    firstDayOfNextMonthSt,
    "fa",
    "YYYY/MM/DD"
  );
  const lastDayOfMonth = firstDayOfNextMonth.add(-1, "d");

  const firstDayOfMonthJalali =
    lastDayOfMonth.format("jYYYY") + "/" + lastDayOfMonth.format("jMM") + "/01";

  const firstDayOfMonth = moment.from(
    firstDayOfMonthJalali,
    "fa",
    "YYYY/MM/DD"
  );

  // console.log("firstDayOfMonth", firstDayOfMonth.format("jYYYY-jMM-jDD"));
  // console.log("lastDayOfMonth", lastDayOfMonth.format("jYYYY-jMM-jDD"));

  return [
    new Date(firstDayOfMonth.format("YYYY-MM-DD")),
    new Date(lastDayOfMonth.format("YYYY-MM-DD")),
  ];
};

export const onFindSpecificNode = (pathnames, menuList) => {
  let specificNode = menuList;

  pathnames.forEach((path) => {
    const item = specificNode.child.find((menu) => menu.oldLink === path);

    if (item) {
      specificNode = item;
    }
  });

  return specificNode;
};

export const filterArrayByArrayId = ({ list, key, ids = [] }) => {
  let finalList = [];

  if (ids.length > 0) {
    finalList = list.filter((option) => {
      return ids.findIndex((x) => x === option[key]) >= 0;
    });
  }

  return finalList;
};
