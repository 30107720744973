import { api, authHeader } from "../configs/axiosConfigs";

const getRoleInfoListApi = async () => {
  const response = await api.request({
    url: `UM/RoleInfo/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getCreateRoleInfoApi = async () => {
  const response = await api.request({
    url: `UM/RoleInfo/Create`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getRoleInfoByIdApi = async (id) => {
  const response = await api.request({
    url: `UM/RoleInfo/GetRoleInfoById${id ? "?Id=" + id : ""}`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const modifyRoleInfoApi = async (data) => {
  const response = await api.request({
    url: `UM/RoleInfo/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteRoleInfoApi = async (data) => {
  const response = await api.request({
    url: `UM/RoleInfo/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};

// user info
const getUserInfoListApi = async () => {
  const response = await api.request({
    url: `UM/UserInfo/GetList`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const getUserInfoByIdApi = async (id) => {
  const response = await api.request({
    url: `UM/UserInfo/GetById?Id=${id}`,
    method: "GET",
    headers: authHeader(),
  });

  return response.data;
};

const modifyUserInfoApi = async (data) => {
  const response = await api.request({
    url: `UM/UserInfo/Modify`,
    method: "Post",
    data: data,
    headers: authHeader(),
  });

  return response.data;
};

const deleteUserInfoApi = async (data) => {
  const response = await api.request({
    url: `UM/UserInfo/Delete`,
    method: "Delete",
    data,
    headers: authHeader(),
  });

  return response.data;
};

const UserManagementService = {
  getRoleInfoListApi,
  getCreateRoleInfoApi,
  getRoleInfoByIdApi,
  modifyRoleInfoApi,
  deleteRoleInfoApi,
  getUserInfoListApi,
  getUserInfoByIdApi,
  modifyUserInfoApi,
  deleteUserInfoApi,
};

export default UserManagementService;
