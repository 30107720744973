import React, { useState, useParams } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from "@mui/material";
import { useLoaderData, useNavigate } from "react-router-dom";

import AccountTree from "@mui/icons-material/AccountTree";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MoveUp from "@mui/icons-material/MoveUp";
import SyncIcon from "@mui/icons-material/Sync";
import { useLocation } from "react-router-dom";
import BreadcrumbsComponent from "../../../../components/shared/breadcrumbs";
import EnhancedTable from "../../../../components/shared/table";
import DeleteConfirm from "../../../../components/shared/Dialog/DeleteConfirm";
import useCheckPermission from "../../../../common/hooks/useCheckPermission";
import DefaultApiService from "../../../../../infrastructure/api/default";

const ContentPage = () => {

  
  const [parentIds, setParentIds] = useState([0]);

  const location = useLocation();
  const { returnedParentIds } = location.state || {}; 
 console.log('rrrrrrrrrrrrrrrrrrrrrrrrrrrrrr' , returnedParentIds);
  if(returnedParentIds!= null && returnedParentIds.length>1)
  {
    parentIds = returnedParentIds;
  }
  const [treeId, setTreeId] = useState(0); // Step 1: Create a state variable to store treeId


  console.log(parentIds);
  const brandInfoList = useLoaderData("root");

  let navigate = useNavigate();
  const [deleteConfirm, setDeleteConfirm] = useState({
    open: false,
    data: {},
    cascadeDelete: false,
  });
  const headCells = [
    {
      id: "updateOn",
      label: "تاریخ بروزرسانی",
      type: "date",
    },
    {
      id: "title",
      label: "عنوان",
    },
    {
      id: "levelKind",
      disablePadding: false,
      label: "نوع",
    },
    {
      id: "isActive",
      type: "boolean",
      disablePadding: false,
      label: "وضعیت",
    },
    {
      id: "imageUrl",
      disablePadding: false,
      type: "image",
      label: "تصویر",
    },
  ];

  const onChildClicked = (row) => {
    setTreeId(row.id); // Step 2: Set the treeId when a row is clicked
    setParentIds((prevParentIds) => [...prevParentIds, row.id]);
    navigate(`/JP/Management/Content/${row.id}`, { state: { parentIds } });
  };

  const onEditClicked = (row) => {
    navigate(
      `/JP/Management/Content/edit/${row.id}/${+parentIds[
        parentIds.length - 1
      ]}`,
      { state: { parentIds } }
    );
  };

  const onDeleteClicked = (row) => {
    setDeleteConfirm((old) => ({ ...old, open: true, data: row }));
  };

  const confirmedDelete = () => {
    return DefaultApiService.deleteOneApi("/jp/Management/content", {
      id: deleteConfirm.data.id,
      cascadeDelete: deleteConfirm.cascadeDelete,
    });
  };

  const onFetchData = () => {
    navigate();
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <BreadcrumbsComponent />

        <DeleteConfirm
          message={`برای حذف "${deleteConfirm.data.title}" اطمینان دارید؟`}
          open={deleteConfirm.open}
          onClose={(loadAgain) => {
            setDeleteConfirm({
              open: false,
              data: {},
              cascadeDelete: false,
            });
            if (loadAgain) {
              navigate();
            }
          }}
          onSubmit={confirmedDelete}
        >
          <FormControlLabel
            name="cascadeDelete"
            checked={deleteConfirm.cascadeDelete}
            onChange={(event) => {
              setDeleteConfirm((old) => ({
                ...old,
                cascadeDelete: event.target.checked,
              }));
            }}
            control={<Switch color="danger" />}
            label="حذف از تمامی جاهایی که استفاده شده است"
          />
        </DeleteConfirm>
      </Grid>

      <Grid item xs={12} md={12}>
        <Card>
          <CardHeader
            title="دسته بندی"
            action={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                }}
              >
                {treeId !== null && (
                  <Button
                    onClick={() => {
                      let previousParentId = 0;
                      const n = parentIds.length;

                      if (n >= 2) {
                        previousParentId = parentIds[n - 2];
                        parentIds.pop();
                      } else {
                        previousParentId = 0;
                      }

                      if (previousParentId == null || previousParentId == 0) {
                        navigate(`/JP/Management/Content`, {
                          state: { parentIds },
                        });
                      } else {
                        navigate(`/JP/Management/Content/${previousParentId}`, {
                          state: { parentIds },
                        });
                      }
                    }}
                    startIcon={<MoveUp />}
                  >
                    برگشت
                  </Button>
                )}

                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1 }}
                />
                 
                {useCheckPermission("create") && (
                  <Button
                    onClick={() => {
                      navigate(
                        `/JP/Management/Content/create/0/${parentIds[parentIds.length - 1]}`,
                        { state: { parentIds } }
                      );
                    }}
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                  >
                    ایجاد
                  </Button>
                )}

                <Divider
                  orientation="vertical"
                  variant="middle"
                  flexItem
                  sx={{ mx: 1 }}
                />

                <IconButton
                  variant="text"
                  color="primary"
                  onClick={onFetchData}
                >
                  <SyncIcon />
                </IconButton>
              </Box>
            }
          />

          <CardContent>
            <Grid container>
              <Grid item xs={12} md={12}>
                <EnhancedTable
                  rowKey={"id"}
                  rows={brandInfoList}
                  headCells={headCells}
                  pageSize={10}
                  actions={[
                    {
                      icon: useCheckPermission("edit") ? (
                        <AccountTree sx={{ width: "1rem", height: "1rem" }} />
                      ) : null,
                      onClick: onChildClicked,
                      color: "primary",
                    },

                    {
                      icon: useCheckPermission("edit") ? (
                        <EditIcon sx={{ width: "1rem", height: "1rem" }} />
                      ) : null,
                      onClick: onEditClicked,
                      color: "primary",
                    },
                    {
                      icon: useCheckPermission("delete") ? (
                        <DeleteIcon sx={{ width: "1rem", height: "1rem" }} />
                      ) : null,
                      onClick: onDeleteClicked,
                      color: "secondary",
                    },
                  ]}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ContentPage;
